import {customElement, property, queryAll, state} from "lit/decorators.js";
import {PageScrollbar} from "../../../../common/pageScrollbar";
import {resolve} from "../../../../container";
import {html, LitElement, type TemplateResult} from "lit";
import Styles from "./mobileHeaderMenu.lit.scss";
import {
    mobileNavigationClosedEvent,
    mobileNavigationOpenedEvent,
    NAVIGATION_DOWN_EVENT,
    NAVIGATION_UP_EVENT,
    type NavigationDownEvent
} from "./mobileHeaderEvents";
import {NavigationItem, NavigationRootItem, NavModel} from "../common/navModel";
import type {MetaLink} from "../common/navLinkTypes";
import {NAVIGATION_JUMP_INSIDE_PAGE_EVENT, type NavigationJumpInsidePageEvent} from "../common/headerEvents";
import type {EopNavigationSheet} from "./navigationSheet";
import {PageFeatures} from "../../../elements/pageFeatures";
import {elementFrom} from "../../../../common/utils/html";
import {ManagingResources} from "../../../../common/lifetime";
import {ScrollService} from "../../../../common/scroll";
import {GLOBAL} from "../../../../common/globals";
import {ClickTracking} from "../../../../tracking/clickTracking";
import {waitTime} from "../../../../common/utils/promises";
import {TrackingEventLocation, TrackingEventName, TrackingEventType} from "../../../../tracking/types";
import {KEYS, onTabPressAfter, prepareEvents} from "../../../../common/utils/events";

const MENU_CLOSING_DURATION = 700;

@customElement("eop-mobile-header-menu")
export class EopMobileHeaderMenu extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @property({attribute: "with-language-switch", type: Boolean})
    public withLanguageSwitch: boolean = false;
    @property({attribute: "with-dark-mode-toggle", type: Boolean})
    public withDarkModeToggle: boolean = false;
    @property({reflect: true, type: Boolean})
    public open: boolean = false;
    @property({reflect: true, type: Boolean})
    public closedInstantly: boolean = false;
    @property({attribute: "sticky", type: Boolean})
    public sticky: boolean = false;
    @state()
    private navRoot: NavigationRootItem;
    @state()
    private metaLinks: MetaLink[] = [];
    @state()
    private currentSheetLevel: number | null = null;
    @state()
    private currentSubNavigationItems: Map<number, NavigationItem> = new Map<number, NavigationItem>;

    @queryAll(".mobile-navigation-scroll-area")
    private scrollAreas: NodeListOf<HTMLDivElement>;

    private backdrop: Element;

    public constructor(
        private pageScrollbar: PageScrollbar = resolve(PageScrollbar),
        private scrollService: ScrollService = resolve(ScrollService),
        private pageFeatures: PageFeatures = resolve(PageFeatures),
        private tracking: ClickTracking = resolve(ClickTracking),
        private navModel: NavModel = resolve(NavModel)
    ) {
        super();
        this.metaLinks = this.navModel.getMetaLinks();
        this.navRoot = this.navModel.makeNavRoot();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.backdrop = this.pageFeatures.insert(elementFrom(`<div class="header-mobile-menu-backdrop"></div>`), this);

        this.addEventListener(NAVIGATION_DOWN_EVENT, (event: Event) => {
            const item = (event as NavigationDownEvent).detail;
            this.openLinkOnNextSheet(item);
        });

        this.addEventListener(NAVIGATION_UP_EVENT, () => {
            this.returnToPreviousSheet();
        });

        this.addEventListener(NAVIGATION_JUMP_INSIDE_PAGE_EVENT, (event: Event) => {
            const targetHref = (event as NavigationJumpInsidePageEvent).detail;
            this.navRoot.activateFor(this.navModel.getPathHierarchy(), targetHref);
            this.navRoot = NavigationRootItem.copyFrom(this.navRoot);
            this.closeMenuInstantly();
        });

        prepareEvents(GLOBAL.document())
            .boundTo(this)
            .on("keydown", (ev) => this.escapeKeyHandler(ev));
    }

    public render(): TemplateResult {
        return html`
            <div class="mobile-header-search-button-container">
                <eop-navigation-search-link without-label></eop-navigation-search-link>
            </div>
            <button class="mobile-header-menu-button-container" data-eventelement="mobile-menu-toggle"
                    @click=${this.toggleMenu}
                    data-tracking-label="mobile-menu-toggle"
                    aria-label="Menu"
            >
                <div class="hamburger-icon">
                    <div class="top-bun"></div>
                    <div class="patty"></div>
                    <div class="bottom-bun"></div>
                </div>
            </button>
            <div class="mobile-navigation-viewport">
                <div class="mobile-navigation-viewport-container">
                    <div class="mobile-navigation-sheet-container ${this.currentSheetLevel ? `level-${this.currentSheetLevel}-active` : ""}">
                        <div class="mobile-navigation-scroll-area">
                            <eop-navigation-sheet
                                    .navItem=${this.navRoot.getActiveSector()}
                                    .sectorLinks=${this.navRoot.getSectors()}
                                    .metaLinks=${this.metaLinks}
                                    ?with-language-switch=${this.withLanguageSwitch}
                                    ?with-dark-mode-toggle=${this.withDarkModeToggle}
                                    ?active=${this.currentSheetLevel === 2}
                                    .level=${2}
                            >
                            </eop-navigation-sheet>
                        </div>
                        ${this.renderSubSheets()}
                    </div>
                </div>
            </div>
        `;
    }

    private renderSubSheets(): TemplateResult[] {
        const results: TemplateResult[] = [];
        this.currentSubNavigationItems.forEach((item, level) => results.push(html`
            <div class="mobile-navigation-scroll-area">
                <eop-navigation-sheet
                        .navItem=${item}
                        ?active=${this.currentSheetLevel === level}
                        .level=${level}
                        .open=${this.open}
                >
                </eop-navigation-sheet>
            </div>
        `));
        return results;
    }

    private openMenu(): void {
        this.open = true;
        this.closedInstantly = false;
        this.pageScrollbar.disablePageScrollability();
        this.currentSheetLevel = 2;
        this.backdrop.classList.add("visible");
        this.dispatchEvent(mobileNavigationOpenedEvent());
        onTabPressAfter(() => this.lastFocusableElementOfActiveSheet(), () => this.closeMenu());
    }

    private closeMenu(): void {
        this.open = false;
        void this.restScrollPositionAfterClosing();
        this.pageScrollbar.enablePageScrollability();
        this.currentSheetLevel = null;
        this.backdrop.classList.remove("visible");
        this.dispatchEvent(mobileNavigationClosedEvent());
    }

    public lastFocusableElementOfActiveSheet(): HTMLElement | null {
        const sheet = this.activeSheet();
        return sheet ? sheet.lastFocusableElement() : null;
    }

    private escapeKeyHandler(evt: KeyboardEvent): void {
        if (evt.key === KEYS.ESCAPE) {
            evt.stopPropagation();
            this.closeMenu();
        }
    }

    private async restScrollPositionAfterClosing(): Promise<void> {
        await waitTime(MENU_CLOSING_DURATION);
        this.scrollAreas.item(0).scrollTop = 0;
    }

    private toggleMenu(): void {
        if (this.open) {
            this.closeMenu();
            this.trackMobileMenuClosed();
            return;
        }
        if (this.sticky || this.headerIsFullyVisible()) {
            this.openMenu();
            this.trackMobileMenuOpened();
            return;
        }
        this.scrollService.scrollToTop(100).then(() => {
            this.openMenu();
            this.trackMobileMenuOpened();
        });
    }

    private trackMobileMenuOpened(): void {
        this.tracking.handleNavigationInteract({
            name: TrackingEventName.NAVIGATION_INTERACT,
            data: {
                label: "mobile-menu-toggle",
                contentId: "header.mobile-menu-toggle",
                location: TrackingEventLocation.HEADER,
                type: TrackingEventType.OPEN
            }
        });
    }

    private trackMobileMenuClosed(): void {
        this.tracking.handleNavigationInteract({
            name: TrackingEventName.NAVIGATION_INTERACT,
            data: {
                label: "mobile-menu-toggle",
                contentId: "header.mobile-menu-toggle",
                location: TrackingEventLocation.HEADER,
                type: TrackingEventType.CLOSE
            }
        });
    }

    private headerIsFullyVisible(): boolean {
        return GLOBAL.htmlElement().getBoundingClientRect().top >= 0;
    }

    private closeMenuInstantly(): void {
        this.closedInstantly = true;
        this.closeMenu();
    }

    private openLinkOnNextSheet(item: NavigationItem): void {
        this.activeSheet()?.scrollIntoView(true);
        this.currentSheetLevel = this.currentSheetLevel! + 1;
        this.currentSubNavigationItems.set(this.currentSheetLevel, item);
    }

    private returnToPreviousSheet(): void {
        this.currentSheetLevel = this.currentSheetLevel! - 1;
    }

    private activeSheet(): EopNavigationSheet | null {
        return this.renderRoot.querySelector("eop-navigation-sheet[active]");
    }
}