import {html, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";

@customElement("eop-svg-image-special-format")
export class EopSvgImageSpecialFormat extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    private alt: string;
    @property({attribute: "functional-icons"})
    private functionalIcons: string = "";
    @property({attribute: "animated", type: Boolean})
    public animated: boolean;

    public render(): TemplateResult {
        return html`
            <div class="svg-image-container">
                <img src=${this.isSrcReady() ? this.srcCurrent : ""}
                     alt=${this.alt ?? ""}
                     loading="lazy"
                     class="svg-image"
                     ?baseplate=${this.enableBaseplate}/>
                ${this.renderFunctionalIcons()}
            </div>`;
    }

    private renderFunctionalIcons(): TemplateResult | null {
        if (this.functionalIcons.length === 0) {
            return null;
        }
        const icons = this.functionalIcons
            .split(",")
            .map((icon: string) => html`
                <div class=${icon}></div>`);
        return html`${icons}`;
    }

    protected isAnimated(): boolean {
        return this.animated;
    }
}