import {html, type TemplateResult} from "lit";
import {customElement, property} from "lit/decorators.js";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";

@customElement("eop-svg-image-with-js-animation-special-format")
export class EopSvgImageWithJsAnimationSpecialFormat extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    private alt: string;
    @property({attribute: "functional-icons"})
    private functionalIcons: string = "";

    public render(): TemplateResult {
        return html`
            <div class="svg-image-container">
                <object type="image/svg+xml"
                        class="svg-image"
                        aria-label=${this.alt}
                        data=${this.isSrcReady() ? this.srcCurrent : ""}
                        ?baseplate=${this.enableBaseplate}>
                </object>
                ${this.renderFunctionalIcons()}
            </div>`;
    }

    private renderFunctionalIcons(): TemplateResult | null {
        if (this.functionalIcons.length === 0) {
            return null;
        }
        const icons = this.functionalIcons
            .split(",")
            .map((icon: string) => html`
                <div class=${icon}></div>`);
        return html`${icons}`;
    }

    protected isAnimated(): boolean {
        return true;
    }
}