import {ManagingResources} from "../common/lifetime";
import {autoRegister, resolve} from "../container";
import {prepareEvents} from "../common/utils/events";
import {GLOBAL} from "../common/globals";
import {Tracking} from "./tracking";
import {Nav} from "../common/nav";
import {TrackingContext} from "./trackingContext";
import {
    type GenericTrackingEventPayload,
    type LinkInfo,
    type NavigationTrackingEvent,
    type NavigationTrackingEventPayload,
    type TrackableMouseEvent,
    type TrackingEvent,
    TrackingEventLocation,
    TrackingEventName,
    TrackingEventType
} from "./types";
import type {PropertyMap} from "../common/utils/objects";

@autoRegister()
export class ClickTracking {

    public constructor(
        private nav: Nav = resolve(Nav),
        private tracking: Tracking = resolve(Tracking)
    ) {
    }

    public handleTextlinkInteract(event: Event, element: Element): void {
        const context = new TrackingContext(element);
        if (!context) {
            return;
        }

        this.markAsTracked(event);
        this.tracking.emitTrackingEvent({
            name: TrackingEventName.TEXTLINK_INTERACT,
            data: {
                label: context.getEventLabel(),
                contentId: TrackingContext.makeContentId(event),
                type: TrackingEventType.CLICK
            }
        });
    }

    public handleNavigationInteract(event: NavigationTrackingEvent): void {
        this.tracking.emitNavigationTrackingEvent(event);
    }

    public handleBodyClick(event: TrackableMouseEvent): void {
        if (event.hasBeenTracked) {
            return;
        }
        const context = TrackingContext.fromEvent(event);
        if (!context) {
            return;
        }
        const trackable = this.trackingEventFromAttributes(event);
        if (trackable) {
            if (trackable.name === TrackingEventName.NAVIGATION_INTERACT) {
                return this.tracking.emitNavigationTrackingEvent({
                    name: trackable.name,
                    data: trackable.data as NavigationTrackingEventPayload
                });
            }
            return this.tracking.emitTrackingEvent({
                name: trackable.name,
                data: trackable.data as GenericTrackingEventPayload
            });
        }
        const eventName = this.getEventName(event, context);
        if (eventName === TrackingEventName.NAVIGATION_INTERACT) {
            return this.tracking.emitNavigationTrackingEvent({
                name: eventName,
                data: this.omitUndefined({
                    label: context.getEventLabel(),
                    contentId: TrackingContext.makeContentId(event),
                    type: TrackingEventType.CLICK,
                    location: this.locationOf(event)
                })
            });
        }
        return this.tracking.emitTrackingEvent({
            name: eventName,
            data: this.omitUndefined({
                label: context.getEventLabel(),
                contentId: TrackingContext.makeContentId(event),
                type: TrackingEventType.CLICK,
                link: this.linkInfo(context.clickedElement.getAttribute("href"))
            })
        });
    }

    private omitUndefined<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    private locationOf(event: Event): TrackingEventLocation {
        const eventPath: string[] = Array.from(event.composedPath())
            .filter(element => element instanceof Element)
            .map(element => element.tagName);
        if (eventPath.includes("HEADER")) {
            return TrackingEventLocation.HEADER;
        }
        if (eventPath.includes("FOOTER")) {
            return TrackingEventLocation.FOOTER;
        }
        return TrackingEventLocation.BODY;
    }

    private markAsTracked(event: Event): void {
        Object.defineProperty(event, "hasBeenTracked", {value: true, writable: true});
    }

    private linkInfo(targetUrl: string | null): LinkInfo | undefined {
        if (!targetUrl) {
            return undefined;
        }
        return {
            targetUrl: this.nav.absoluteHrefFrom(targetUrl),
            isExternal: !this.nav.isInternalLink(targetUrl)
        };
    }

    private getEventName(event: TrackableMouseEvent, context: TrackingContext): TrackingEventName {
        if (this.isButtonElement(context)) {
            return TrackingEventName.BUTTON_INTERACT;
        }
        if (this.isTeaserElement(context)) {
            return TrackingEventName.TEASER_INTERACT;
        }
        if (this.isNavigationElement(event)) {
            return TrackingEventName.NAVIGATION_INTERACT;
        }
        return TrackingEventName.TEXTLINK_INTERACT;
    }

    private trackingEventFromAttributes(event: TrackableMouseEvent): TrackingEvent<any> | undefined {
        const trackableElement = Array.from(event.composedPath())
            .filter(element => element instanceof Element)
            .filter(element => element.hasAttribute("data-tracking-event-name"))
            .first()!;
        if (!trackableElement) {
            return;
        }
        const name = trackableElement.getAttribute("data-tracking-event-name") as TrackingEventName;
        const data = this.extractDataFromTrackingAttributes(trackableElement);
        data.contentId = TrackingContext.makeContentId(event);
        return {name, data};
    }

    private extractDataFromTrackingAttributes(trackableElement: Element): PropertyMap {
        return trackableElement.getAttributeNames().reduce((result, attribute) => {
            if (attribute.startsWith("data-tracking-payload-")) {
                const dataKey = attribute.replace("data-tracking-payload-", "");
                return {...result, [dataKey]: trackableElement.getAttribute(attribute)};
            }
            return result;
        }, {});
    }

    private isNavigationElement(event: TrackableMouseEvent): boolean {
        const composedPath: string[] = Array.from(event.composedPath())
            .filter(element => element instanceof Element)
            .map(element => element.tagName);

        return composedPath.includes("HEADER")
            || composedPath.includes("FOOTER")
            || composedPath.includes("EOP-CHAPTER-NAVIGATION")
            || composedPath.includes("EOP-CONTENT-NAVIGATION");
    }

    private isButtonElement(context: TrackingContext): boolean {
        const eventElementName = context.eventElement?.getAttribute("data-eventelement");
        return !!eventElementName && eventElementName === "button";
    }

    private isTeaserElement(context: TrackingContext): boolean {
        const eventElementName = context.eventElement?.getAttribute("data-eventelement");
        const teaserEventElements = [
            "imageteaser",
            "prio0teaser",
            "prio1teaser",
            "prio2teaser",
            "prio3teaser",
            "productteaser",
            "feedpreview",
            "feedslider",
            "feedslideroverlay",
            "pagepreview",
            "pagepreviewslider",
            "feedrelated",
            "social-wall",
            "feedsearch",
            "dashboard-tile",
            "side-teaser",
            "image-tile",
            "teaser-sequence-tile-image",
            "teaser-sequence-tile-text",
            "alles-im-blick"
        ];
        return !!eventElementName && teaserEventElements.includes(eventElementName);
    }
}

export class EopClickTracking extends ManagingResources(HTMLElement) {

    public constructor(private clickTrackingListener: ClickTracking = resolve(ClickTracking)) {
        super();
    }

    public connectedCallback(): void {
        prepareEvents(GLOBAL.bodyElement())
            .boundTo(this)
            .on("click", ev => this.clickTrackingListener.handleBodyClick(ev as TrackableMouseEvent));
    }
}

customElements.define("eop-click-tracking", EopClickTracking);