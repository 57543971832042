import type {TrackingListener} from "../listener";
import {Tracking} from "../tracking";
import {autoRegister, resolve} from "../../container";
import {NativeGoogleTagManager} from "./nativeGtm";
import {type FormTrackingEvent, type GenericTrackingEvent, type NavigationTrackingEvent, type VideoTrackingEvent} from "../types";

@autoRegister()
export class GoogleTagManagerTracking {
    public constructor(private nativeGoogleTagManager: NativeGoogleTagManager = resolve(NativeGoogleTagManager)) {
    }

    public emitTrackingEvent(event: GenericTrackingEvent): void {
        const linkInfo = this.linkInfo(event);
        const trackingEvent = {
            event: "generic_event",
            event_name: event.name,
            [event.name]: this.omitUndefined({
                text: event.data.label,
                type: event.data.type,
                cms_id: event.data.contentId,
                link_target_url: linkInfo?.target,
                link_direction: linkInfo?.direction
            })
        };

        this.nativeGoogleTagManager.push(trackingEvent);
    }

    public emitNavigationTrackingEvent(event: NavigationTrackingEvent): void {
        const trackingEvent = {
            event: "generic_event",
            event_name: event.name,
            [event.name]: this.omitUndefined({
                text: event.data.label,
                type: event.data.type,
                cms_id: event.data.contentId,
                location: event.data.location
            })
        };
        this.nativeGoogleTagManager.push(trackingEvent);
    }

    public emitVideoTrackingEvent(event: VideoTrackingEvent): void {
        const videoEvent = {
            event: "generic_event",
            event_name: event.name,
            [event.name]: this.omitUndefined({
                video_title: event.data.videoTitle,
                video_percent: event.data.videoProgress
            })
        };
        this.nativeGoogleTagManager.push(videoEvent);
    }

    public emitFormTrackingEvent(event: FormTrackingEvent): void {
        const formEvent = {
            event: "generic_event",
            event_name: event.name,
            [event.name]: {
                name: event.data.name
            }
        };
        this.nativeGoogleTagManager.push(formEvent);
    }

    private omitUndefined<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    private linkInfo(event: GenericTrackingEvent): { target: string; direction: string } | undefined {
        if (!event.data.link) {
            return undefined;
        }
        return {
            target: event.data.link?.targetUrl,
            direction: event.data.link?.isExternal ? "external" : "internal"
        };
    }
}

export class GoogleTagManagerTrackingListener implements TrackingListener {

    public constructor(private googleTagManagerTracking: GoogleTagManagerTracking) {
    }

    public emitTrackingEvent(event: GenericTrackingEvent): void {
        this.googleTagManagerTracking.emitTrackingEvent(event);
    }

    public emitNavigationTrackingEvent(event: NavigationTrackingEvent): void {
        this.googleTagManagerTracking.emitNavigationTrackingEvent(event);
    }

    public emitVideoTrackingEvent(event: VideoTrackingEvent): void {
        this.googleTagManagerTracking.emitVideoTrackingEvent(event);
    }

    public emitFormTrackingEvent(event: FormTrackingEvent): void {
        this.googleTagManagerTracking.emitFormTrackingEvent(event);
    }
}

@autoRegister()
export class GoogleTagManagerTrackingListenerFactory {
    public constructor(private googleTagManagerTracking: GoogleTagManagerTracking = resolve(GoogleTagManagerTracking)) {
    }

    public createListener(): GoogleTagManagerTrackingListener {
        return new GoogleTagManagerTrackingListener(this.googleTagManagerTracking);
    }
}

export class EopGoogleTagManagerTracking extends HTMLElement {

    public constructor(
        private tracking: Tracking = resolve(Tracking),
        private googleTagManagerTrackingListenerFactory: GoogleTagManagerTrackingListenerFactory = resolve(GoogleTagManagerTrackingListenerFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        const googleTagManagerTrackingListener = this.googleTagManagerTrackingListenerFactory.createListener();
        this.tracking.registerUnnamedListener(googleTagManagerTrackingListener);
    }
}

customElements.define("eop-google-tag-manager-tracking", EopGoogleTagManagerTracking);