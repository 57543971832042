import {customElement, property, queryAssignedElements, state} from "lit/decorators.js";
import {html, LitElement, type PropertyValues, type TemplateResult} from "lit";
import Styles from "./contentSlider.lit.scss";
import {Interval} from "../../../common/interval";
import {resolve} from "../../../container";
import {HorizontalSwipeHandler} from "../../../common/utils/events";
import {AutoInitializing} from "../../../common/elements";
import {ManagingResources} from "../../../common/lifetime";
import {EopSliderElement} from "../eopSliderElement";
import {OUT_OF_VIEW_ATTR} from "../image/imageUtils";

@customElement("eop-content-slider")
export class EopContentSlider extends AutoInitializing(ManagingResources(LitElement)) {

    public static readonly styles = Styles;

    @queryAssignedElements({slot: "content-slider-element"})
    private sliderElements: HTMLElement[];
    @property({attribute: "baseplate-enabled", type: Boolean})
    private baseplateEnabled: boolean = false;
    @property({attribute: "autoslide-interval"})
    private autoslideInterval: number;
    @state()
    private activeIndex: number = 0;
    private numberOfElements: number = 0;
    private autoslidePromise: Promise<void>;

    public constructor(private interval: Interval = resolve(Interval)) {
        super();

        new HorizontalSwipeHandler([this])
            .onSwipeLeft((e) => this.next())
            .onSwipeRight((e) => this.previous())
            .activate();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.numberOfElements = this.querySelectorAll("div[slot='content-slider-element']").length;
        if (this.autoslideInterval > 0) {
            this.autoslidePromise = this.interval.repeat(() => this.next(), this.autoslideInterval * 1000);

            this.addEventListener("click", () => {
                this.interval.cancel(this.autoslidePromise);
            }, {once: true});
            this.addEventListener("touchstart", () => {
                this.interval.cancel(this.autoslidePromise);
            }, {once: true});
        }
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();
        this.interval.cancel(this.autoslidePromise);
    }

    protected firstUpdated(_changedProperties: PropertyValues): void {
        super.firstUpdated(_changedProperties);
        this.sliderElements.forEach((sliderElement) => {
            new EopSliderElement(sliderElement).image?.setAttribute(OUT_OF_VIEW_ATTR, "");
        });
        this.toggleActivationClass();
    }

    public render(): TemplateResult {
        return html`
            <div class="content-slider ${this.baseplateEnabled ? "reverse-background" : ""}"
                 ?baseplate=${this.baseplateEnabled}>
                <slot name="content-slider-element"></slot>
                ${this.renderButtons()}
                ${this.renderIndicators()}
            </div>
        `;
    }

    private renderButtons(): TemplateResult {
        return html`
            <button @click=${this.previous} class="prev-button" type="button" data-tracking-label="content-slider-previous"
                    aria-label="previous slide"></button>
            <button @click=${this.next} class="next-button" type="button" data-tracking-label="content-slider-next" aria-label="next slide"></button>
        `;
    }

    private renderIndicators(): TemplateResult {
        const indicatorTemplates = [];
        for (let i = 0; i < this.numberOfElements; i++) {
            indicatorTemplates.push(html`
                <button @click=${this.indicator}
                        index=${i}
                        class="${i === this.activeIndex ? "active" : ""} indicator"
                        aria-label=${"slide " + (i + 1)}
                        data-tracking-label="content-slider-indicator">
                </button>`);
        }
        return html`
            <div class="indicators">
                ${indicatorTemplates}
            </div>`;
    }

    private previous(): void {
        this.toggleActivationClass();
        this.activeIndex = (this.activeIndex - 1 + this.numberOfElements) % this.numberOfElements;
        this.toggleActivationClass();
    }

    private next(): void {
        this.toggleActivationClass();
        this.activeIndex = (this.activeIndex + 1) % this.numberOfElements;
        this.toggleActivationClass();
    }

    private indicator(e: Event): void {
        this.toggleActivationClass();
        this.activeIndex = (e.target as Element).getAttribute("index")!.toInt();
        this.toggleActivationClass();
    }

    private toggleActivationClass(): void {
        const activeElement = this.sliderElements[this.activeIndex];
        if (activeElement.classList.contains("active")) {
            activeElement.classList.remove("active");
            activeElement.classList.add("inactive");
        } else {
            new EopSliderElement(activeElement).image?.removeAttribute(OUT_OF_VIEW_ATTR);
            activeElement.classList.remove("inactive");
            activeElement.classList.add("active");
        }
    }
}