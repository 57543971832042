import type {TrackingListener} from "./listener";
import {Tracking} from "./tracking";
import {resolve} from "../container";
import type {FormTrackingEvent, GenericTrackingEvent, NavigationTrackingEvent, VideoTrackingEvent} from "./types";

export class DebugTrackingServiceListener implements TrackingListener {
    public emitTrackingEvent(event: GenericTrackingEvent): void {
        console.log("debug tracking event:", event);
    }

    public emitNavigationTrackingEvent(event: NavigationTrackingEvent): void {
        console.log("debug navigation tracking event:", event);
    }

    public emitVideoTrackingEvent(event: VideoTrackingEvent): void {
        console.log("debug video tracking event:", event);
    }

    public emitFormTrackingEvent(event: FormTrackingEvent): void {
        console.log("debug form tracking event:", event);
    }
}

export class EopDebugTracking extends HTMLElement {

    public constructor(private tracking: Tracking = resolve(Tracking)) {
        super();
    }

    public connectedCallback(): void {
        this.tracking.registerUnnamedListener(new DebugTrackingServiceListener());
    }
}

customElements.define("eop-debug-tracking", EopDebugTracking);