import {autoRegister, resolve} from "../../../container";
import {Configuration} from "../../../common/config";

export const IMAGEPROXY_URL = "IMAGEPROXY_URL";
export const JPG_QUALITY = "JPG_QUALITY";

@autoRegister()
export class ImageProxyUrlFactory {

    public constructor(private config: Configuration = resolve(Configuration)) {
    }

    public resolutionUrlBase(
        src: string, width: number, height: number, focusX?: number, focusY?: number, zoomFactor?: number
    ): string {
        const imageProxyUrl = this.config.get(IMAGEPROXY_URL);
        const jpgQuality = this.config.get(JPG_QUALITY);
        let url = `${imageProxyUrl}${width}x${height},q${jpgQuality}`;

        if (focusX && focusY && zoomFactor) {
            url = `${url},focus${focusX}x${focusY},zoom${zoomFactor}`;
        }

        return `${url}/${src}`;
    }
}