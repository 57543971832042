import type {HeaderMenuConfig, MetaLink, NavLinkLevel1, NavLinkLevel2, NavLinkLevel3, NavLinkLevel4} from "./navLinkTypes";
import {UrlParts} from "../../../../common/utils/url";
import {autoRegister, resolve} from "../../../../container";
import {Nav} from "../../../../common/nav";
import {GLOBAL} from "../../../../common/globals";
import {isArray} from "../../../../bootstrap/common/arrays";

export abstract class NavigationItem {
    protected constructor(
        public id: string,
        public label: string,
        public href?: string,
        public target?: string,
        public active: boolean = false,
        public searchHref?: string
    ) {
    }

    public activateForExactUrl(url: string): boolean {
        this.active = false;

        this.getSubItems().forEach(item => item.activateForExactUrl(url));
        if (this.getSubItems().some(item => item.active)) {
            this.active = true;
        }

        if (this.matches(url)) {
            this.active = true;
        }

        return this.active;
    }

    public activeForCurrentUrl(): boolean {
        const {href, origin} = GLOBAL.window().location;
        return `${origin}${this.href}` === href;
    }

    public hasLink(): boolean {
        return !!this.href && this.href !== "";
    }

    public abstract getSubItems(): NavigationItem[];

    public abstract hasSubItems(): boolean;

    protected matches(href: string): boolean {
        if (!this.href) {
            return false;
        }

        if (this.href.includes("?")) {
            return false;
        }

        return this.href === href;
    }
}

export class NavigationLevel4Item extends NavigationItem {
    public constructor(
        id: string,
        label: string,
        href?: string,
        target?: string,
        public active: boolean = false
    ) {
        super(id, label, href, target);
    }

    public static from(navLink: NavLinkLevel4, index: number): NavigationLevel4Item {
        return new NavigationLevel4Item(
            normalizeId(`${navLink.label}-${index}`),
            navLink.label,
            navLink.href,
            navLink.target,
            false
        );
    }

    public static copyFrom(navItem: NavigationLevel4Item): NavigationLevel4Item {
        return new NavigationLevel4Item(
            navItem.id,
            navItem.label,
            navItem.href,
            navItem.target,
            navItem.active
        );
    }

    public getSubItems(): NavigationItem[] {
        return [];
    }

    public hasSubItems(): boolean {
        return false;
    }
}

export class TeaserNavigationLevel4Item extends NavigationItem {
    public constructor(
        id: string,
        label: string,
        public imageSrc: string,
        public imageSize: string,
        public imageBackground: string,
        public imageShade: string,
        public videoSrc?: string,
        href?: string,
        target?: string,
        public imageAlt?: string,
        public headlineLevel?: number,
        public active: boolean = false
    ) {
        super(id, label, href, target);
    }

    public static from(navLink: NavLinkLevel4, index: number): TeaserNavigationLevel4Item {
        return new TeaserNavigationLevel4Item(
            normalizeId(`${navLink.label}-${index}`),
            navLink.label,
            navLink.imageSrc!,
            navLink.imageSize!,
            navLink.imageBackground!,
            navLink.imageShade!,
            navLink.videoSrc,
            navLink.href,
            navLink.target,
            navLink.imageAlt,
            navLink.headlineLevel
        );
    }

    public static copyFrom(navItem: TeaserNavigationLevel4Item): TeaserNavigationLevel4Item {
        return new TeaserNavigationLevel4Item(
            navItem.id,
            navItem.label,
            navItem.imageSrc,
            navItem.imageSize,
            navItem.imageBackground,
            navItem.imageShade,
            navItem.videoSrc,
            navItem.href,
            navItem.target,
            navItem.imageAlt,
            navItem.headlineLevel,
            navItem.active
        );
    }

    public getSubItems(): NavigationItem[] {
        return [];
    }

    public hasSubItems(): boolean {
        return false;
    }
}

export class NavigationLevel3Item extends NavigationItem {
    public constructor(
        id: string,
        label: string,
        href?: string,
        target?: string,
        active: boolean = false,
        private subItems: NavigationLevel4Item[] = []
    ) {
        super(id, label, href, target, active);
    }

    public static from(navLink: NavLinkLevel3, index: number): NavigationLevel3Item {
        return new NavigationLevel3Item(
            normalizeId(`${navLink.label}-${index}`),
            navLink.label,
            navLink.href,
            navLink.target,
            false,
            navLink.subLinks?.map((link, i) => NavigationLevel4Item.from(link, i))
        );
    }

    public static copyFrom(navItem: NavigationLevel3Item): NavigationLevel3Item {
        return new NavigationLevel3Item(
            navItem.id,
            navItem.label,
            navItem.href,
            navItem.target,
            navItem.active,
            navItem.subItems.map(link => NavigationLevel4Item.copyFrom(link)));
    }

    public getSubItems(): NavigationItem[] {
        return this.subItems;
    }

    public hasSubItems(): boolean {
        return this.subItems.isNotEmpty();
    }
}

export class TeaserNavigationLevel3Item extends NavigationItem {
    public constructor(
        id: string,
        label: string,
        href?: string,
        target?: string,
        public imageSrc?: string,
        public imageSize?: string,
        public imageBackground?: string,
        public imageShade?: string,
        public videoSrc?: string,
        public imageAlt?: string,
        public headlineLevel?: number,
        active: boolean = false,
        private subItems: TeaserNavigationLevel4Item[] = []
    ) {
        super(id, label, href, target, active);
    }

    public static from(navLink: NavLinkLevel3, index: number): TeaserNavigationLevel3Item {
        return new TeaserNavigationLevel3Item(
            normalizeId(`${navLink.label}-${index}`),
            navLink.label,
            navLink.href,
            navLink.target,
            navLink.imageSrc,
            navLink.imageSize,
            navLink.imageBackground,
            navLink.imageShade,
            navLink.videoSrc,
            navLink.imageAlt,
            navLink.headlineLevel,
            false,
            navLink.subLinks?.map((link, i) => TeaserNavigationLevel4Item.from(link, i))
        );
    }

    public static copyFrom(navItem: TeaserNavigationLevel3Item): TeaserNavigationLevel3Item {
        return new TeaserNavigationLevel3Item(
            navItem.id,
            navItem.label,
            navItem.href,
            navItem.target,
            navItem.imageSrc,
            navItem.imageSize,
            navItem.imageBackground,
            navItem.imageShade,
            navItem.videoSrc,
            navItem.imageAlt,
            navItem.headlineLevel,
            navItem.active,
            navItem.subItems.map(link => TeaserNavigationLevel4Item.copyFrom(link)));
    }

    public getSubItems(): TeaserNavigationLevel4Item[] {
        return this.subItems;
    }

    public hasSubItems(): boolean {
        return this.subItems.isNotEmpty();
    }
}

export class NavigationLevel2Item extends NavigationItem {
    public constructor(
        id: string,
        label: string,
        href?: string,
        target?: string,
        active: boolean = false,
        private subItemsColumn1: NavigationLevel3Item[] = [],
        private subItemsColumn2: NavigationLevel3Item[] = [],
        private subItemsColumn3: NavigationLevel3Item[] = []
    ) {
        super(id, label, href, target, active);
    }

    public static from(navLink: NavLinkLevel2, index: number): NavigationLevel2Item {
        return new NavigationLevel2Item(
            normalizeId(`${navLink.label}-${index}`),
            navLink.label,
            navLink.href,
            navLink.target,
            false,
            navLink.subLinksColumn1?.map((link, i) => NavigationLevel3Item.from(link, i)),
            navLink.subLinksColumn2?.map((link, i) => NavigationLevel3Item.from(link, i)),
            navLink.subLinksColumn3?.map((link, i) => NavigationLevel3Item.from(link, i))
        );
    }

    public static copyFrom(navItem: NavigationLevel2Item): NavigationLevel2Item {
        return new NavigationLevel2Item(
            navItem.id,
            navItem.label,
            navItem.href,
            navItem.target,
            navItem.active,
            navItem.subItemsColumn1.map(link => NavigationLevel3Item.copyFrom(link)),
            navItem.subItemsColumn2.map(link => NavigationLevel3Item.copyFrom(link)),
            navItem.subItemsColumn3.map(link => NavigationLevel3Item.copyFrom(link))
        );
    }

    public getSubItems(): NavigationLevel3Item[] {
        return [...this.subItemsColumn1, ...this.subItemsColumn2, ...this.subItemsColumn3];
    }

    public hasSubItems(): boolean {
        return this.getSubItems().isNotEmpty();
    }

    public getSubItemsInColumns(): NavigationLevel3Item[][] {
        return [this.subItemsColumn1, this.subItemsColumn2, this.subItemsColumn3].filter(it => it.isNotEmpty());
    }
}

export class TeaserNavigationLevel2Item extends NavigationItem {
    public constructor(
        id: string,
        label: string,
        href?: string,
        target?: string,
        active: boolean = false,
        private subItems: TeaserNavigationLevel3Item[] = []
    ) {
        super(id, label, href, target, active);
    }

    public static from(navLink: NavLinkLevel2, index: number): TeaserNavigationLevel2Item {
        return new TeaserNavigationLevel2Item(
            normalizeId(`${navLink.label}-${index}`),
            navLink.label,
            navLink.href,
            navLink.target,
            false,
            navLink.subLinks?.map((link, i) => TeaserNavigationLevel3Item.from(link, i))
        );
    }

    public static copyFrom(navItem: TeaserNavigationLevel2Item): TeaserNavigationLevel2Item {
        return new TeaserNavigationLevel2Item(
            navItem.id,
            navItem.label,
            navItem.href,
            navItem.target,
            navItem.active,
            navItem.subItems.map(link => TeaserNavigationLevel3Item.copyFrom(link))
        );
    }

    public getSubItems(): TeaserNavigationLevel3Item[] {
        return this.subItems;
    }

    public hasSubItems(): boolean {
        return this.subItems.isNotEmpty();
    }

    public getSubItemsInRows(): (TeaserNavigationLevel3Item | TeaserNavigationLevel3Item[])[] {
        return this.subItems.reduce((acc, it) => {
            if (it.hasSubItems()) {
                return [...acc, it];
            }
            const last = acc.last();
            const row = isArray(last) ? last : [];
            const rest = isArray(last) ? acc.slice(0, -1) : acc;
            return [...rest, [...row, it]];
        }, [] as (TeaserNavigationLevel3Item | TeaserNavigationLevel3Item[])[]);
    }
}

export function isNavLevel2Item(value: NavigationLevel2Item | TeaserNavigationLevel2Item | NavLinkLevel2): value is NavigationLevel2Item {
    const isNavigationLevel2Item = value.hasOwnProperty("subItemsColumn1")
        || value.hasOwnProperty("subItemsColumn2")
        || value.hasOwnProperty("subItemsColumn3");

    const isTeaserNavigationLevel2Item = value.hasOwnProperty("subItems");
    const isNavLinkLevel2 = value.hasOwnProperty("subLinks");

    return isNavigationLevel2Item || (!isTeaserNavigationLevel2Item && !isNavLinkLevel2);
}

export class NavigationLevel1Item extends NavigationItem {
    public constructor(
        id: string,
        label: string,
        public sectorIdentifier?: string,
        href?: string,
        target?: string,
        active: boolean = false,
        private subItems: (NavigationLevel2Item | TeaserNavigationLevel2Item)[] = [],
        searchHref?: string
    ) {
        super(id, label, href, target, active, searchHref);
    }

    public static from(navLink: NavLinkLevel1, index: number): NavigationLevel1Item {
        return new NavigationLevel1Item(
            normalizeId(`${navLink.label}-${index}`),
            navLink.label,
            navLink.sectorIdentifier,
            navLink.href,
            navLink.target,
            false,
            navLink.subLinks?.map((link, i) => isNavLevel2Item(link)
                ? NavigationLevel2Item.from(link, i)
                : TeaserNavigationLevel2Item.from(link, i)),
            navLink.searchHref
        );
    }

    public static copyFrom(navItem: NavigationLevel1Item): NavigationLevel1Item {
        return new NavigationLevel1Item(
            navItem.id,
            navItem.label,
            navItem.sectorIdentifier,
            navItem.href,
            navItem.target,
            navItem.active,
            navItem.subItems.map(link => isNavLevel2Item(link)
                ? NavigationLevel2Item.copyFrom(link)
                : TeaserNavigationLevel2Item.copyFrom(link)),
            navItem.searchHref
        );
    }

    public getSubItems(): (NavigationLevel2Item | TeaserNavigationLevel2Item)[] {
        return this.subItems;
    }

    public hasSubItems(): boolean {
        return this.subItems.isNotEmpty();
    }
}

export type PathHierarchy = string[];

export class NavigationRootItem extends NavigationItem {
    public constructor(private subItems: NavigationLevel1Item[]) {
        super("", "");
    }

    public static from(navLinks: NavLinkLevel1[]): NavigationRootItem {
        return new NavigationRootItem(navLinks.map((link, i) => NavigationLevel1Item.from(link, i)));
    }

    public static copyFrom(navRoot: NavigationRootItem): NavigationRootItem {
        return new NavigationRootItem(
            navRoot.subItems.map(item => NavigationLevel1Item.copyFrom(item)));
    }

    public activateFor(pathHierarchy: PathHierarchy, url: string): void {
        const urlParts = UrlParts.from(url);
        const currentPath = pathHierarchy[0];

        let activated = this.activateForExactUrl(currentPath + urlParts.query + urlParts.anchor);
        if (activated) {
            return;
        }

        activated = this.activateForExactUrl(currentPath + urlParts.query);
        if (activated) {
            return;
        }

        for (const urlCandidate of pathHierarchy) {
            activated = this.activateForExactUrl(urlCandidate);
            if (activated) {
                return;
            }
        }
    }

    public getSubItems(): NavigationLevel1Item[] {
        return this.subItems;
    }

    public getSectors(): NavigationLevel1Item[] {
        return this.subItems;
    }

    public countSectors(): number {
        return this.subItems.length;
    }

    public getActiveSector(): NavigationLevel1Item | null {
        return this.subItems
            .findFirst(sector => sector.active) ?? null;
    }

    public hasSubItems(): boolean {
        return this.subItems.isNotEmpty();
    }

    public getActiveItemChain(): string[] {
        const itemChain: string[] = [];

        let activeItem: NavigationItem | undefined = this.subItems.findFirst(item => item.active);
        while (activeItem) {
            itemChain.push(activeItem.label);
            activeItem = activeItem.getSubItems().findFirst(item => item.active);
        }

        return itemChain;
    }
}

@autoRegister()
export class NavModel {
    private readonly searchHref?: string;
    private readonly metaLinksHeadline?: string;
    private readonly metaLinks: MetaLink[];
    private readonly pathHierarchy: PathHierarchy;
    private readonly sectorLinksHeadline?: string;
    private readonly navRoot: NavigationRootItem;

    public constructor(
        private nav: Nav = resolve(Nav)
    ) {
        const config = GLOBAL.bodyElement().querySelector("header")?.readRawData<HeaderMenuConfig>("navigation-config");

        this.searchHref = config?.searchHref;
        this.metaLinksHeadline = config?.metaLinksHeadline;
        this.metaLinks = config?.metaLinks ?? [];
        this.pathHierarchy = config?.urlHierarchy ?? [];
        this.sectorLinksHeadline = config?.sectorLinksHeadline;
        this.navRoot = NavigationRootItem.from(config?.navLinks ?? []);
        this.navRoot.activateFor(this.pathHierarchy, this.nav.pathname() + this.nav.getAnchor());
    }

    public makeNavRoot(): NavigationRootItem {
        return NavigationRootItem.copyFrom(this.navRoot);
    }

    public getMetaLinksHeadline(): string | undefined {
        return this.metaLinksHeadline;
    }

    public getMetaLinks(): MetaLink[] {
        return this.metaLinks;
    }

    public getPathHierarchy(): PathHierarchy {
        return this.pathHierarchy;
    }

    public hasMetaLinks(): boolean {
        return !this.metaLinks.isEmpty();
    }

    public getSearchHref(): string | undefined {
        return this.searchHref;
    }

    public getSectorLinksHeadline(): string | undefined {
        return this.sectorLinksHeadline;
    }

    public getActiveSector(): NavigationLevel1Item | null {
        return this.navRoot.getActiveSector();
    }
}

const normalizeId = (str: string): string => str.toLowerCase().replace(/\\s+/, "-");