import {OUT_OF_VIEW_ATTR} from "./imageUtils";
import {customElement, property, query} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {PlainImageAdjuster} from "./plainImageCommons";
import {eopCustomEvent} from "../../../common/utils/events";

@customElement("eop-svg-image-with-js-animation")
export class EopSvgImageWithJsAnimation extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;
    @property({attribute: "functional-icons"})
    private functionalIcons: string = "";

    @query("object")
    private objectElement: HTMLObjectElement;

    private imageAdjuster: PlainImageAdjuster;

    public constructor() {
        super();
        this.imageAdjuster = new PlainImageAdjuster(() => this.objectElement, this);
    }

    public render(): TemplateResult {
        return html`
            <div class="plain-image">
                <div class="plain-image-container">
                    <object type="image/svg+xml"
                            aria-label=${this.alt}
                            data=${this.isSrcReady() ? this.srcCurrent : ""}
                            @load=${{handleEvent: () => this.handleImageLoaded(), once: false}}
                            ?baseplate=${this.enableBaseplate}>
                    </object>
                    ${this.renderFunctionalIcons()}
                </div>
            </div>
        `;
    }

    private renderFunctionalIcons(): TemplateResult | null {
        if (this.functionalIcons.length === 0) {
            return null;
        }
        const icons = this.functionalIcons
            .split(",")
            .map((icon: string) => html`
                <div class=${icon}></div>`);
        return html`${icons}`;
    }

    private handleImageLoaded(): void {
        this.imageAdjuster.adjust(this.srcCurrent);
        this.dispatchEvent(eopCustomEvent("imageLoaded"));
    }

    protected isAnimated(): boolean {
        return true;
    }
}