export type TrackableMouseEvent = MouseEvent & {
    hasBeenTracked: boolean;
};

export enum TrackingEventName {
    TEXTLINK_INTERACT = "textlink_interact",
    BUTTON_INTERACT = "button_interact",
    TEASER_INTERACT = "teaser_interact",
    NAVIGATION_INTERACT = "navigation_interact",
    ACCORDION_INTERACT = "accordion_interact",
    VIDEO_START = "video_start",
    VIDEO_PROGRESS = "video_progress",
    VIDEO_COMPLETE = "video_complete",
    FORM_SUCCESS = "form_success"
}

export enum TrackingEventType {
    CLICK = "click", OPEN = "open", CLOSE = "close"
}

export enum TrackingEventLocation {
    BODY = "body", HEADER = "header", FOOTER = "footer"
}

type GenericTrackingEventProps = {
    label: string;
    contentId: string;
    type: TrackingEventType;
};

export type LinkInfo = {
    targetUrl: string;
    isExternal: boolean;
};

export type GenericTrackingEventPayload = GenericTrackingEventProps & {
    link?: LinkInfo;
};

export type NavigationTrackingEventPayload = GenericTrackingEventProps & {
    location: TrackingEventLocation;
};

export type TrackingEvent<T> = {
    name: TrackingEventName;
    data: T
};

export type GenericTrackingEvent = TrackingEvent<GenericTrackingEventPayload>;

export type NavigationTrackingEvent = TrackingEvent<NavigationTrackingEventPayload>;

export type VideoTrackingEvent = TrackingEvent<{
    videoTitle: string;
    videoProgress?: number;
}>;

export type FormTrackingEvent = TrackingEvent<{ name: string }>;