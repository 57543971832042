import {GLOBAL} from "./globals";
import {autoRegister} from "../container";

export type ViewportOffsetProvider = (scrollTarget: number) => number;

const ZERO_OFFSET: ViewportOffsetProvider = () => 0;

@autoRegister()
export class Page {

    private viewportOffsetProvider: ViewportOffsetProvider;

    public constructor() {
        this.viewportOffsetProvider = ZERO_OFFSET;
    }

    public totalScrollHeight(): number {
        const height = GLOBAL.bodyElement().computedStyle().height || "0px";
        return height.toInt();
    }

    public visualViewportHeight(): number {
        return GLOBAL.visualViewport()?.height ?? this.viewportHeight();
    }

    public viewportHeight(): number {
        return GLOBAL.window().innerHeight;
    }

    public viewportWidth(): number {
        return GLOBAL.window().innerWidth;
    }

    public getYScrollPosition(): number {
        return GLOBAL.window().scrollY;
    }

    public distanceToBottom(): number {
        return this.totalScrollHeight() - this.getYScrollPosition() - this.viewportHeight();
    }

    public isAtBottom(): boolean {
        return this.distanceToBottom() < 1;
    }

    public percentageScrolled(): number {
        return Math.round((this.getYScrollPosition() + this.viewportHeight()) / this.totalScrollHeight() * 100);
    }

    public registerViewportOffsetProvider(offsetProvider: ViewportOffsetProvider): void {
        this.viewportOffsetProvider = offsetProvider;
    }

    public unregisterViewportOffsetProvider(): void {
        this.viewportOffsetProvider = ZERO_OFFSET;
    }

    public getViewportOffsetWhenScrolledTo(scrollTarget: number): number {
        return this.viewportOffsetProvider(scrollTarget);
    }

    public getEffectiveYScrollPosition(): number {
        return this.getYScrollPosition() - this.getViewportOffsetWhenScrolledTo(this.getYScrollPosition());
    }

    public static activeElement(): Element | null {
        let activeElement = GLOBAL.activeElement();
        if (!activeElement) {
            return null;
        }
        let nestedActiveElement;
        do {
            if (!!activeElement && activeElement.shadowRoot && !!activeElement.shadowRoot.activeElement) {
                nestedActiveElement = activeElement.shadowRoot.activeElement;
                activeElement = nestedActiveElement;
            } else if (activeElement instanceof HTMLIFrameElement && !!activeElement.contentDocument) {
                nestedActiveElement = activeElement.contentDocument?.activeElement;
                activeElement = nestedActiveElement;
            } else {
                nestedActiveElement = undefined;
            }
        } while (nestedActiveElement);
        return activeElement;
    }
}