import {parseResolutionSource, ResolutionSource} from "./imageUtils";
import {clamp} from "../../../bootstrap/common/numbers";

const IMAGE_TOO_WIDE_FOR_PARENT_CLASS = "image-too-wide-for-parent";
const LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS = "landscape-image-too-tall-for-parent";
const PORTRAIT_IMAGE_CLASS = "portrait-image";
const LANDSCAPE_IMAGE_CLASS = "landscape-image";

type FocusTransformPercentages = {
    translateX: number,
    translateY: number,
    zoom: number
}

export class PlainImageAdjuster {
    public constructor(private imageElementProvider: () => HTMLElement, private parent: HTMLElement) {
    }

    public adjust(src: string): void {
        const imageElement = this.imageElementProvider();
        const focusData = parseResolutionSource(src) ?? new ResolutionSource();

        const parentWidth = this.parent.outerWidth();
        const parentHeight = this.parent.outerHeight();
        const imageWidth = imageElement.outerWidth();
        const imageHeight = imageElement.outerHeight();
        const parentAspectRatio = parentWidth / parentHeight;
        const aspectRatio = imageWidth / imageHeight;

        const cssClasses = determineCssClasses(aspectRatio, parentAspectRatio);
        imageElement.classList.remove(IMAGE_TOO_WIDE_FOR_PARENT_CLASS);
        imageElement.classList.remove(LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS);
        imageElement.classList.add(...cssClasses);

        const {translateX, translateY, zoom} = calculateFocusTransformPercentages(focusData, aspectRatio, parentAspectRatio);
        imageElement.style.setProperty("--focus-translate", `-${translateX}% -${translateY}%`);
        imageElement.style.setProperty("--zoom", `${zoom}%`);
    }
}

function determineCssClasses(aspectRatio: number, parentAspectRatio: number): string[] {
    const cssClasses: string[] = [];
    if (aspectRatio > parentAspectRatio) {
        cssClasses.push(IMAGE_TOO_WIDE_FOR_PARENT_CLASS);
    }
    if (aspectRatio < parentAspectRatio && aspectRatio > 1) {
        cssClasses.push(LANDSCAPE_IMAGE_TOO_TALL_FOR_PARENT_CLASS);
    }
    if (aspectRatio <= 1) {
        cssClasses.push(PORTRAIT_IMAGE_CLASS);
    }
    if (aspectRatio > 1) {
        cssClasses.push(LANDSCAPE_IMAGE_CLASS);
    }
    return cssClasses;
}

function calculateFocusTransformPercentages(focusData: ResolutionSource, aspectRatio: number, parentAspectRatio: number): FocusTransformPercentages {
    const zoomFactor = focusData.zoomFactor ?? 1;
    let translateXPercentage = focusData.focusX ?? 50;
    let translateYPercentage = focusData.focusY ?? 50;

    if (aspectRatio >= parentAspectRatio) {
        translateXPercentage = clamp(translateXPercentage,
            100 * parentAspectRatio / (2 * aspectRatio * zoomFactor),
            100 * (1 - parentAspectRatio / (2 * aspectRatio * zoomFactor)));
        translateYPercentage = clamp(translateYPercentage,
            100 * (1 / (2 * zoomFactor)),
            100 * (1 - 1 / (2 * zoomFactor)));
    } else {
        translateXPercentage = clamp(translateXPercentage,
            100 * (1 / (2 * zoomFactor)),
            100 * (1 - 1 / (2 * zoomFactor)));
        translateYPercentage = clamp(translateYPercentage,
            100 * aspectRatio / (2 * parentAspectRatio * zoomFactor),
            100 * (1 - aspectRatio / (2 * parentAspectRatio * zoomFactor)));
    }

    return {translateX: translateXPercentage, translateY: translateYPercentage, zoom: zoomFactor * 100};
}