import {customElement, property, query, queryAll, queryAssignedElements, state} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./tabNavigation.lit.scss";
import {ScrollableBar} from "./scrollableBar";

@customElement("eop-tabs")
export class EopTabs extends ScrollableBar {

    public static readonly styles = Styles;

    @property({attribute: "tab-layout", type: String})
    public layout: string = "horizontal";
    @property({attribute: "baseplate-enabled", reflect: true, type: Boolean})
    public baseplateEnabled: boolean = false;
    @queryAssignedElements({slot: "tab-content"})
    private tabContents: HTMLElement[];
    @query(".tabs")
    protected scrollableArea: HTMLElement;
    @queryAll(".tab")
    private tabs: NodeListOf<HTMLElement>;
    @state()
    private activeTabIndex: number = 0;

    private tabLabels: string[];

    protected items(): HTMLElement[] {
        return Array.from(this.tabs);
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.tabLabels = [];
        Array.from(this.querySelectorAll("div[slot='tab-content']").entries()).forEach(([, tabContent], index) => {
            this.tabLabels.push(tabContent.getAttribute("title")!);
            if (index === 0) {
                tabContent.classList.add("active");
            }
            if (this.layout === "vertical") {
                tabContent.classList.add("layout-element-nested-8");
            }
        });
    }

    public render(): TemplateResult | undefined {
        return html`
            <div class="tab-container ${this.layout}">
                <nav class="tab-navigation">
                    <div role="tablist" class="tabs horizontally-scrollable-area"
                         @scroll=${this.toggleScrollButtonVisibility}
                         @touchend=${{handleEvent: () => this.toggleScrollButtonVisibility(), passive: true}}
                    >
                        ${(this.renderTabLabels())}
                        <div aria-hidden="true">
                            <eop-swipe-hint></eop-swipe-hint>
                        </div>
                    </div>
                    ${this.renderScrollLeftButton()}
                    ${this.renderScrollRightButton()}
                </nav>
                ${this.renderSlots()}
            </div>
        `;
    }

    private renderTabLabels(): TemplateResult[] {
        return this.tabLabels.map((tabLabel, index) => {
            const handleTabClick = (e: Event): void => this.clickTab(e, index);
            return html`
                <button role="tab"
                        class="tab ${this.isActivateTab(index) ? "active" : ""}"
                        aria-selected=${this.isActivateTab(index)}
                        @click=${handleTabClick}
                >
                    <span class="tab-label">${tabLabel}</span>
                </button>`;
        });
    }

    private renderSlots(): TemplateResult {
        return html`
            <div class="tab-contents">
                <slot name="tab-content" class="tab-content"></slot>
            </div>
        `;
    }

    private clickTab(event: Event, index: number): void {
        event.preventDefault();

        this.activeTabIndex = index;
        this.tabContents.forEach(tabContent => tabContent.classList.remove("active"));
        this.tabContents[index].classList.add("active");
        this.scrollToItem(index);
    }

    private isActivateTab(index: number): boolean {
        return this.activeTabIndex === index;
    }
}