import {customElement, property, state} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./navigationFlyoutToggle.lit.scss";
import type {NavigationLevel2Item, TeaserNavigationLevel2Item} from "../common/navModel";
import {classMap} from "lit/directives/class-map.js";
import {navigationToggleFlyoutEvent} from "./desktopHeaderEvents";
import {EopNavigationItemGroup} from "../common/navigationItemGroup";
import {TrackingEventLocation, TrackingEventName, TrackingEventType} from "../../../../tracking/types";

@customElement("eop-navigation-flyout-toggle")
export class EopNavigationFlyoutToggle extends EopNavigationItemGroup {

    public static readonly styles = Styles;

    @state()
    private model: NavigationLevel2Item | TeaserNavigationLevel2Item;
    @property({reflect: true, type: Boolean})
    public selected: boolean = false;

    public render(): TemplateResult {
        const active = this.model.active || this.model.activeForCurrentUrl();
        const classes = classMap({["nav-link"]: true, highlighted: active});
        if (this.model.hasSubItems()) {
            return html`
                <button type="button"
                        title=${this.model.label}
                        aria-haspopup="menu"
                        class=${classes}
                        @click=${this.toggleSelect}
                        data-tracking-event-name=${TrackingEventName.NAVIGATION_INTERACT}
                        data-tracking-payload-label=${this.model.label}
                        data-tracking-payload-location=${TrackingEventLocation.HEADER}
                        data-tracking-payload-type=${this.interactionType()}
                >
                    ${this.model.label}
                </button>`;
        }
        return html`
            <a href=${this.model.href}
               target=${this.model.target}
               title=${this.model.label}
               class=${classes}
               data-tracking-event-name=${TrackingEventName.NAVIGATION_INTERACT}
               data-tracking-payload-label=${this.model.label}
               data-tracking-payload-location=${TrackingEventLocation.HEADER}
               data-tracking-payload-type=${this.interactionType()}
            >
                ${this.model.label}
            </a>`;
    }

    public toggleSelect(): void {
        this.selected = !this.selected;
        this.dispatchEvent(navigationToggleFlyoutEvent(this.model.id, this.model.label));
    }

    public hasId(id: string): boolean {
        return this.model.id === id;
    }

    public focusableElement(): HTMLElement {
        return this.renderRoot.querySelector<HTMLElement>("button") || this.renderRoot.querySelector<HTMLElement>("a")!;
    }

    private interactionType(): TrackingEventType {
        if (this.selected) {
            return TrackingEventType.OPEN;
        }
        return this.model.hasSubItems() ? TrackingEventType.CLOSE : TrackingEventType.CLICK;
    }

}