import {LocatedNumbers} from "../../common/utils/locatedNumbers";
import {Visibility} from "../../common/visibility";
import {DelayableOnceActionConfig, DelayableOnceActionFactory} from "../../common/delayableOnceAction";
import * as Arrays from "../../bootstrap/common/arrays";
import {autoRegister, resolve} from "../../container";
import {forAllEntries, IntersectionObserverFactory} from "../../common/observation";
import {Timeout} from "../../common/timeout";
import {isDefined} from "../../common/utils/basics";


const ANIMATION_RESOLUTION = 200;
export const ANIMATION_DURATION = 1700;
export const VISIBILITY_DURATION_UNTIL_ANIMATION = 250;

export type AnimationFrame = { time: number; value: number };
type AnimationFrameFormatted = { time: number; formattedValue: string };

export class EopAnimatedNumber extends HTMLElement {

    private animatedNumber: AnimatedNumber;

    public constructor(private animatedNumberFactory: AnimatedNumberFactory = resolve(AnimatedNumberFactory)) {
        super();
    }

    public connectedCallback(): void {
        const value = this.parseValue();
        const precision = this.parsePrecision();
        const useNumberGroupSeparator = this.hasAttribute("show-number-group-separator");

        const builder = this.animatedNumberFactory.builder().withNumberGroupSeparator(useNumberGroupSeparator);
        if (isDefined(precision)) {
            builder.withFixedPrecision(precision);
        }
        this.animatedNumber = builder.appliedTo(this);

        this.animatedNumber.update(value);
    }

    private parseValue(): number {
        try {
            return this.getAttribute("number")?.toFloat() ?? NaN;
        } catch (e) {
            return NaN;
        }
    }

    private parsePrecision(): number | undefined {
        try {
            return this.getAttribute("precision")?.toInt();
        } catch (e) {
            return undefined;
        }
    }

    public disconnectedCallback(): void {
        this.animatedNumber.disconnect();
    }
}

export class AnimatedNumberBuilder {
    private startValue?: number;
    private fixedPrecision?: number;
    private invalidationText?: string;
    private useNumberGroupSeparator?: boolean;
    private customFormatter?: (numberValue: number, stringValue: string) => string;

    public withFixedPrecision(precision: number): this {
        if (precision < 0) {
            throw new Error("Animated number cannot be initialized with negative precision: " + precision);
        }

        this.fixedPrecision = precision;
        return this;
    }

    public withInvalidationText(invalidationText: string): this {
        this.invalidationText = invalidationText;
        return this;
    }

    public withStartValue(startValue: number): this {
        this.startValue = startValue;
        return this;
    }

    public withNumberGroupSeparator(value: boolean): this {
        this.useNumberGroupSeparator = value;
        return this;
    }

    public withCustomFormatting(format: (numberValue: number, stringValue: string) => string): this {
        this.customFormatter = format;
        return this;
    }

    public appliedTo(element: HTMLElement): AnimatedNumber {
        return new AnimatedNumber(element, this.startValue, this.fixedPrecision, this.invalidationText, this.useNumberGroupSeparator, this.customFormatter);
    }
}

export class AnimatedNumber {
    private currentValue: number;
    private firstVisibleValue: number;

    private active: boolean;
    private currentAnimation: Promise<void>[];
    private intersectionObserver: IntersectionObserver | undefined;

    public constructor(
        private element: HTMLElement,
        private startValue: number | undefined = undefined,
        private fixedPrecision: number | undefined = undefined,
        private invalidationText: string = "",
        private useNumberGroupSeparator: boolean = true,
        private customFormatter: (numberValue: number, stringValue: string) => string = (numberValue, stringValue) => stringValue,
        private timeout: Timeout = resolve(Timeout),
        private locatedNumbers: LocatedNumbers = resolve(LocatedNumbers),
        private delayableOnceActionFactory: DelayableOnceActionFactory = resolve(DelayableOnceActionFactory),
        private visibility: Visibility = resolve(Visibility),
        private intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        this.currentValue = NaN;
        this.firstVisibleValue = NaN;
        this.active = false;
        this.currentAnimation = [];
        this.setupInitialAnimation();
        if (startValue) {
            this.update(startValue);
        }
    }

    public update(newValue: number): void {
        this.cancelRunningAnimation();

        if (isNaN(newValue)) {
            this.invalidate();
            return;
        }

        if (this.isInvalid()) {
            this.resetToZero();
        }

        if (this.active) {
            this.updateElement(newValue);
            this.currentValue = newValue;
        } else {
            this.firstVisibleValue = newValue;
        }
    }

    public disconnect(): void {
        this.intersectionObserver?.disconnect();
    }

    private updateElement(newValue: number): void {
        if (this.visibility.isOutsideViewport(this.element)) {
            const lastDecimalPosition = this.lastDecimalPositionForAnimatingTowards(newValue);
            this.displayValue(this.formatNumber(newValue, lastDecimalPosition));
        } else {
            this.animateTowards(newValue);
        }
    }

    private isInvalid(): boolean {
        return isNaN(this.currentValue);
    }

    private invalidate(): void {
        this.currentValue = NaN;
        this.displayValue(this.invalidationText);
    }

    private resetToZero(): void {
        this.currentValue = 0;
        this.displayValue("0");
    }

    private animateTowards(targetValue: number): void {
        const numberChange = new SwingingNumberChange({
            steps: ANIMATION_RESOLUTION,
            duration: ANIMATION_DURATION,
            startValue: this.currentValue,
            targetValue: targetValue
        });

        const lastDecimalPosition = this.lastDecimalPositionForAnimatingTowards(targetValue);
        numberChange.calculateTimeline()
            .map(frame => this.formatAnimationFrame(frame, lastDecimalPosition))
            .forEach(frame => this.scheduleDisplaying(frame));
    }

    private lastDecimalPositionForAnimatingTowards(targetValue: number): number {
        if (this.fixedPrecision !== undefined) {
            return -this.fixedPrecision;
        } else {
            return Math.min(0, targetValue.lastDecimalPosition());
        }
    }

    private formatAnimationFrame(frame: AnimationFrame, lastDecimalPosition: number): AnimationFrameFormatted {
        return {
            time: frame.time,
            formattedValue: this.formatNumber(frame.value, lastDecimalPosition)
        };
    }

    private formatNumber(x: number, lastDecimalPosition: number): string {
        return this.customFormatter(x,
            this.locatedNumbers.formatNumberString(x.toFixed(-lastDecimalPosition), this.useNumberGroupSeparator));
    }

    private scheduleDisplaying(frame: AnimationFrameFormatted): void {
        this.currentAnimation.push(this.timeout.delay(() => this.displayValue(frame.formattedValue), frame.time));
    }

    private setupInitialAnimation(): void {
        const delayedAnimationConfig = new DelayableOnceActionConfig()
            .withAction(() => {
                this.active = true;
                this.update(this.firstVisibleValue);
            })
            .withCondition(() => this.elementIsInViewportEnough());
        const delayedAnimation = this.delayableOnceActionFactory.newDelayableOnceAction(delayedAnimationConfig);

        this.intersectionObserver = this.intersectionObserverFactory.create(forAllEntries(entry => {
            const shouldRestart = entry.isIntersecting && !delayedAnimation.hasFinished();
            delayedAnimation.triggerIf(shouldRestart, VISIBILITY_DURATION_UNTIL_ANIMATION);
        }), {threshold: 0.3});
        this.intersectionObserver.observe(this.element);
    }

    private elementIsInViewportEnough(): boolean {
        return this.visibility.isVisibleInsideViewportAtPercentage(0.3, this.element);
    }

    private displayValue(value: string): void {
        this.element.textContent = value;
    }

    private cancelRunningAnimation(): void {
        this.currentAnimation.forEach(promise => this.timeout.cancel(promise));
        this.currentAnimation = [];
    }
}

@autoRegister()
export class AnimatedNumberFactory {
    public builder(): AnimatedNumberBuilder {
        return new AnimatedNumberBuilder();
    }
}

type NumberChangeParams = {
    steps: number;
    duration: number;
    startValue: number;
    targetValue: number;
};
type NumberFunction = (x: number) => number;

/*
Calculates an animation timeline, i.e. a series of time-value pairs / frames (t,v) such that at time t, the displayed value should jump to v. The jump times t grow from 0 to the specified duration and the values v grow / decrease from the specified start value to the specified target value, using the specified number of frames. The animation should implement a smooth "swing" effect, i.e. the animation should start slowly, be fast in the middle and ease out again. To achieve that, time steps should develop from being large to being small to being large again, whereas the values should make small steps at the beginning, then large steps, then small steps again. I.e. the functions (frameIndex) => jump time and (frameIndex) => value should behave like:

times t:    grow fast   -> grow slowly -> grow fast
values v:   grow slowly -> grow fast   -> grow slowly.

Calculation of jump times: Only calculate the second half of the jump times, then reuse this half result to easily get the first half without computation.
To calculate the second half, use a function f growing slowly at the beginning and fast at the end (i.e. accelerating) from (0, 0) to (halfSteps, halfDuration), where halfSteps is half the specified number of frames and halfDuration is half the time the animation should last. Applying f to 1, 2, ... halfSteps yields time values in the shape desired for the second half, growing from 0 to halfDuration. The first half is now the same time values, just point reflected, i.e. in reversed order and with a negative sign. So concatinate this array with the original one to get an array with the specified number of frames as length, and values growing fast -> slowly -> fast from -halfDuration to halfDuration, and shift the values by halfDuration to get the result.

Calculation of values: Just works the same for a function f growing fast at the beginning, then slow (i.e. decelerating) from (0,0) to (halfSteps, halfRange).
halfRange = mean of start and target value. Additionally, the resulting values are rounded such that they have no more decimal places than the specified target number.
 */
export class SwingingNumberChange {
    private startValue: number;
    private targetValue: number;
    private halfSteps: number;
    private halfDuration: number;
    private halfRange: number;

    private jumpTimeFunction: NumberFunction;
    private valueFunction: NumberFunction;

    public constructor(params: NumberChangeParams) {
        this.startValue = params.startValue;
        this.targetValue = params.targetValue;
        this.halfSteps = Math.floor(params.steps / 2);
        this.halfDuration = Math.floor(params.duration / 2);
        this.halfRange = (params.targetValue - params.startValue) / 2;

        this.jumpTimeFunction = this.createAcceleratingFunctionFromOriginToTargetXY({
            x: this.halfSteps,
            y: this.halfDuration
        });
        this.valueFunction = this.createDeceleratingFunctionFromOriginToTargetXY({
            x: this.halfSteps,
            y: this.halfRange
        });
    }

    public calculateTimeline(): AnimationFrame[] {
        const times = this.calculateJumpTimes();
        const values = this.calculateIntermediateValues();

        return Arrays.condense(times, values, (time, value) => ({
            time: time,
            value: value
        }));
    }

    private calculateJumpTimes(): number[] {
        const secondHalfOfJumpTimes = Arrays.intRangeClosed(1, this.halfSteps)
            .map(this.jumpTimeFunction);

        return this.pointReflectNumbersWithZeroInTheMiddle(secondHalfOfJumpTimes)
            .map(t => Math.round(t + this.halfDuration));
    }

    private calculateIntermediateValues(): number[] {
        const secondHalfOfValues = Arrays.intRangeClosed(1, this.halfSteps)
            .map(this.valueFunction);

        const result = this.pointReflectNumbersWithZeroInTheMiddle(secondHalfOfValues)
            .map(t => t + this.halfRange + this.startValue);
        // avoid rounding errors at initial and final state
        result[0] = this.startValue;
        result[result.length - 1] = this.targetValue;

        return result;
    }

    private pointReflectNumbersWithZeroInTheMiddle(numbers: number[]): number[] {
        return numbers.inReverseOrder()
            .map(x => -x)
            .concat(0)
            .concat(numbers);
    }

    private createAcceleratingFunctionFromOriginToTargetXY(targetPoint: { x: number; y: number }): NumberFunction {
        return x => targetPoint.y * (3 / 4 * ((1 - Math.sqrt(1 - (x / targetPoint.x) ** 2)) ** 2) + 1 / 4 * x / targetPoint.x);
    }

    private createDeceleratingFunctionFromOriginToTargetXY(targetPoint: { x: number; y: number }): NumberFunction {
        return x => targetPoint.y * (3 / 4 * Math.sin(x * Math.PI / (2 * targetPoint.x)) + 1 / 4 * x / targetPoint.x);
    }
}

customElements.define("eop-animated-number", EopAnimatedNumber);