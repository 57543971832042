import {LanguageProfile, LanguagesService} from "../../../../common/languages";
import {resolve} from "../../../../container";
import {customElement, property, query} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import Styles from "./desktopLanguageSwitch.lit.scss";
import {GLOBAL} from "../../../../common/globals";
import {eventOccurredOutside} from "../../../../common/utils/events";


// GoodExampleCandidateFor: LitComponent with ShadowDOM
@customElement("eop-desktop-language-switch")
export class EopDesktopLanguageSwitch extends LitElement {

    public static readonly styles = Styles;

    @property({attribute: true, reflect: true, type: Boolean})
    public open: boolean = false;
    @query(".language-dropdown")
    private dropdown: HTMLElement;

    private allLanguages: LanguageProfile[];
    private activeLanguage: LanguageProfile;

    public constructor(private languagesService: LanguagesService = resolve(LanguagesService)) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.allLanguages = this.languagesService.allLanguages();
        this.activeLanguage = this.languagesService.activeLanguage();
    }

    public render(): TemplateResult | null {
        if (this.allLanguages.isEmpty()) {
            return null;
        }

        return html`
            <button class="language-switch" @click=${this.toggle} data-tracking-label="language-switch">
                <span class="current-language-label">${this.activeLanguage.id.toUpperCase()}</span>
                <div class="language-dropdown">${(this.renderLanguages())}</div>
            </button>`;
    }

    private renderLanguages(): TemplateResult[] {
        return this.allLanguages.map(lang => this.renderLanguageOption(lang));
    }

    private renderLanguageOption(lang: LanguageProfile): TemplateResult {
        if (this.activeLanguage.id === lang.id) {
            return html`
                <span class="language active">${(lang.name)}</span>
            `;
        } else {
            return html`
                <a href=${lang.href} class="link language inactive">${(lang.name)}</a>
            `;
        }
    }

    private toggle(event: Event): void {
        event.stopPropagation();
        if (eventOccurredOutside(event, this.dropdown)) {
            this.open = !this.open;
        }

        if (this.open) {
            GLOBAL.bodyElement().addEventListener("click", this.close);
        }
    }

    private close = (): void => {
        this.open = false;
        GLOBAL.bodyElement().removeEventListener("click", this.close);
    };

}
