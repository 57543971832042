import {autoRegister, resolve} from "../container";
import type {TrackingListener} from "./listener";
import {NativeGoogleTagManager} from "./gtm/nativeGtm";
import type {FormTrackingEvent, GenericTrackingEvent, NavigationTrackingEvent, VideoTrackingEvent} from "./types";

@autoRegister()
export class Tracking {

    private listeners: TrackingListener[] = [];

    public registerUnnamedListener(listener: TrackingListener): void {
        this.listeners.push(listener);
    }

    public emitTrackingEvent(event: GenericTrackingEvent): void {
        this.listeners.forEach(listener => listener.emitTrackingEvent(event));
    }

    public emitNavigationTrackingEvent(event: NavigationTrackingEvent): void {
        this.listeners.forEach(listener => listener.emitNavigationTrackingEvent(event));
    }

    public emitVideoTrackingEvent(event: VideoTrackingEvent): void {
        this.listeners.forEach(listener => listener.emitVideoTrackingEvent(event));
    }

    public emitFormTrackingEvent(event: FormTrackingEvent): void {
        this.listeners.forEach(listener => listener.emitFormTrackingEvent(event));
    }
}

export class EopTracking extends HTMLElement {

    public constructor(
        private nativeGoogleTagManager: NativeGoogleTagManager = resolve(NativeGoogleTagManager)
    ) {
        super();

        this.nativeGoogleTagManager.init();
    }
}

customElements.define("eop-tracking", EopTracking);