import {GLOBAL} from "../common/globals";
import {CLICK, eventOccurredOutside} from "../common/utils/events";
import {SessionStorage} from "../common/clientStorage";
import {resolve} from "../container";
import {html, LitElement, type TemplateResult} from "lit";
import {customElement, state} from "lit/decorators.js";
import Styles from "./themeToggle.lit.scss";
import {isString} from "../bootstrap/common/strings";
import {EventBus} from "../common/eventBus";


const STORAGE_KEY = "debugTheme";
export const THEME_UPDATED_EVENT = "theme-updated-event";

export class Theme {

    public themeClass: string;

    public constructor(public label: string, public id: string) {
        this.themeClass = "theme-" + id;
    }
}

const THEMES = [
    new Theme("EnBW", "enbw"),
    new Theme("GeoHardt", "geohardt"),
    new Theme("Aranea", "aranea"),
    new Theme("CD-New", "cdnew"),
    new Theme("CD-New-2", "cdnew-2")
];

const themeFromId = (id: string): Theme => {
    return THEMES
            .findFirst(t => t.id === id)
        ?? THEMES[0];
};

const themeFromCssClass = (className: string): Theme => {
    return THEMES
            .findFirst(t => t.themeClass === className)
        ?? THEMES[0];
};

@customElement("eop-theme-toggle")
export class EopThemeToggle extends LitElement {

    public static readonly styles = Styles;

    private initialTheme: Theme;
    @state()
    private label: string;

    private closeOnClickOutside: (event: Event) => void;

    public constructor(
        private sessionStorage: SessionStorage = resolve(SessionStorage),
        private eventBus: EventBus = resolve(EventBus)
    ) {
        super();
        this.label = "";
    }

    public render(): TemplateResult {
        return html`
            <div class="tool reverse-background theme-toggle">
                Theme: <span class="theme">${this.label}</span>
                <div class="theme-toggle-themes">
                    ${this.renderThemes()}
                    <span class='link theme-toggle-reset' @click=${this.reset}>Reset</span>
                </div>
            </div>
        `;
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.initialTheme = themeFromCssClass(this.getCurrentThemeClass());

        this.addEventListener("click", () => this.open());
        this.initTheme();

        this.closeOnClickOutside = (event: Event) => {
            if (eventOccurredOutside(event, this)) {
                this.close();
            }
            return CLICK.CONTINUE_PROPAGATION;
        };

        GLOBAL.bodyElement().addEventListener("keydown", (event: KeyboardEvent) => {
            if (event.altKey && event.key === "t") {
                this.open();
            }
        });
    }

    private renderThemes(): TemplateResult[] {
        return THEMES.map(theme => {
            const handleClick = (): void => this.loadTheme(theme);
            return html`<span class="link theme-toggle-theme" @click=${handleClick}>${theme.label}</span>`;
        });
    }

    private initTheme(): void {
        const storedCustomTheme = this.sessionStorage.get(STORAGE_KEY)?.parseAsJSON() ?? {};
        if (isString(storedCustomTheme?.value)) {
            const customTheme = themeFromId(storedCustomTheme.value);
            this.highlightPersistence();
            this.loadTheme(customTheme);
            return;
        }

        const currentTheme = themeFromCssClass(this.getCurrentThemeClass());
        this.updateThemeLabel(currentTheme);
    }

    private open(): void {
        this.classList.add("theme-toggle-open");
        GLOBAL.bodyElement().addEventListener("click", this.closeOnClickOutside);
    }

    private close(): void {
        this.classList.remove("theme-toggle-open");
        GLOBAL.bodyElement().removeEventListener("click", this.closeOnClickOutside);
    }

    public reset(event: Event): void {
        this.sessionStorage.remove(STORAGE_KEY);
        this.updateTheme(this.initialTheme);
        this.classList.remove("theme-persisted");
        this.close();
        event.stopPropagation();
    }

    private updateTheme(theme: Theme): void {
        this.updateThemeClass(theme);
        this.updateThemeLabel(theme);
        this.themeUpdatedEvent(theme);
    }

    private loadTheme(theme: Theme): void {
        this.updateTheme(theme);
        this.persist(theme);
    }

    private persist(theme: Theme): void {
        this.sessionStorage.save(STORAGE_KEY, {value: theme.id});
        this.highlightPersistence();
    }

    private highlightPersistence(): void {
        this.classList.add("theme-persisted");
    }

    private updateThemeClass(theme: Theme): void {
        const body = GLOBAL.bodyElement();

        const currentThemeClass = this.getCurrentThemeClass();
        if (currentThemeClass) {
            body.classList.remove(currentThemeClass);
        }
        body.classList.add(theme.themeClass);
    }

    private getCurrentThemeClass(): string {
        return Array.from(GLOBAL.bodyElement().classList)
                .findFirst(className => className.startsWith("theme-"))
            ?? "";
    }

    private updateThemeLabel(theme: Theme): void {
        this.label = theme.label;
    }

    private themeUpdatedEvent(theme: Theme): void {
        this.eventBus.dispatchEvent(THEME_UPDATED_EVENT, theme);
    }
}