import {autoRegisterAs, resolve} from "../../container";
import type {SubmitRequest, SubmitResponse, SubmitService} from "../form/formService";
import {MacherbusStorage} from "./macherbusStorage";
import {promiseFromJsonResponse} from "../../common/utils/promises";
import {RequestSugarFactory} from "../common/requestSugar";
import {Tracking} from "../../tracking/tracking";

import {TrackingEventName} from "../../tracking/types";

export const MACHERBUS_REST_BASE = "/enbw-eop/eop-app-macherbus/rest/stable";

@autoRegisterAs("macherbusService")
export class MacherbusService implements SubmitService {

    public constructor(
        private macherbusStorage: MacherbusStorage = resolve(MacherbusStorage),
        private tracking: Tracking = resolve(Tracking)
    ) {
    }

    public async submit(request: SubmitRequest): Promise<SubmitResponse> {
        const votingId = request.formId;
        const lang = request.lang;
        const email = request.formData.contact.email;
        const votes = this.macherbusStorage.votes(votingId);
        const base = MACHERBUS_REST_BASE + "/submitVotes";
        const response = await fetch(`${base}/${votingId}/${lang}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "EOP-Sugar": RequestSugarFactory.produce()
                },
                body: JSON.stringify({
                    eMailAddress: email,
                    votes: votes
                } as SubmitVotesRequest)
            });

        if (response.ok) {
            this.tracking.emitFormTrackingEvent({
                name: TrackingEventName.FORM_SUCCESS,
                data: {
                    name: request.configId
                }
            });
            return {success: true};
        }
        if (response.status === 409) {
            return {success: false, errorId: "doubleVoting"};
        }
        if (response.status === 410) {
            return {success: false, errorId: "deadlineExceeded"};
        }
        return {success: false, errorId: "error"};
    }

    public async confirm(request: DOIRequest): Promise<DOIResponse> {
        const votingId = request.votingId;
        const lang = request.lang;
        const voteCode = request.voteCode;
        const base = MACHERBUS_REST_BASE + "/confirmVotes";

        const response = await fetch(`${base}/${votingId}/${lang}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "EOP-Sugar": RequestSugarFactory.produce()
                },
                body: JSON.stringify({voteCode})
            });

        if (response.ok) {
            return {success: true};
        }
        if (response.status === 410) {
            return {success: false, errorId: "deadline-error"};
        }
        return {success: false, errorId: "error"};
    }

    public async reject(request: DOIRequest): Promise<DOIResponse> {
        const votingId = request.votingId;
        const lang = request.lang;
        const voteCode = request.voteCode;
        const base = MACHERBUS_REST_BASE + "/rejectVotes";

        const response = await fetch(`${base}/${votingId}/${lang}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    "EOP-Sugar": RequestSugarFactory.produce()
                },
                body: JSON.stringify({
                    voteCode: voteCode
                } as DOIRequest)
            });

        if (response.ok) {
            return {success: true};
        }
        if (response.status === 410) {
            return {success: false, errorId: "deadline-error"};
        }
        return {success: false, errorId: "error"};
    }

    public async getRanking(voteId: string, categoryId: string, projectIds: string[]): Promise<Ranking> {
        const base = MACHERBUS_REST_BASE + "/ranking";
        const response = await fetch(`${base}/${voteId}/${categoryId}?` + new URLSearchParams([
            ...(projectIds.map(projectId => ["projectId", projectId]))
        ]).toString(),
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8"
                }
            });
        return promiseFromJsonResponse(response);
    }

}

export type SubmitVotesRequest = {
    eMailAddress: string;
    votes: any;
};

export type DOIRequest = {
    votingId: string;
    lang: string;
    voteCode: string;
};


export type DOIResponse = {
    success: boolean;
    errorId?: string;
};

export type Ranking = {
    rankedProjects: RankedProject[];
};

export type RankedProject = {
    projectId: string;
    voteCount: number;
    rank: number;
};