import {Resolution} from "../../common/resolution";
import {autoRegister, resolve} from "../../container";
import type {ElementFeatureFactory, ReadyElementFeature} from "./elementFeatures";
import {MutationObserverFactory, ResizeObserverFactory} from "../../common/observation";

type HorizontalOverflowHandler = {
    element: Element;
    reactToOverflow: (overflown: boolean) => void;
}

class SwipeWindow {
    private readonly tabbable: boolean;
    private scrollable: boolean;
    private mutationObserver: MutationObserver;
    private resizeObserver: ResizeObserver;
    private handlers: HorizontalOverflowHandler[];

    public constructor(
        public readonly windowElement: Element,
        public contentElement: Element,
        private resolution: Resolution = resolve(Resolution),
        private resizeObserverFactory: ResizeObserverFactory = resolve(ResizeObserverFactory),
        private mutationObserverFactory: MutationObserverFactory = resolve(MutationObserverFactory)
    ) {
        this.tabbable = this.windowElement.hasAttribute("data-tabbable") ?? false;
        this.scrollable = false;
        this.handlers = [];
        this.resizeObserver = this.resizeObserverFactory.create(() => this.checkAndHandleOverflow());
        this.mutationObserver = this.mutationObserverFactory.create(() => this.checkAndHandleOverflow());
    }

    public addRespectiveHandlersFrom(handlers: HorizontalOverflowHandler[]): this {
        handlers
            .filter(handler => this.isResponsibleFor(handler))
            .forEach(respectiveHandler => this.addHandler(respectiveHandler));
        return this;
    }

    public tryAddAndExecuteHandler(handler: HorizontalOverflowHandler): void {
        if (this.isResponsibleFor(handler)) {
            this.addHandler(handler);
            handler.reactToOverflow(this.scrollable);
        }
    }

    private addHandler(handler: HorizontalOverflowHandler): void {
        this.handlers.push(handler);
    }

    public removeHandler(handler: HorizontalOverflowHandler): void {
        this.handlers.removeAll(handler);
    }

    public observeChanges(): void {
        this.resolution.onBootstrapBreakpointChange(() => this.checkAndHandleOverflow());
        this.resizeObserver.observe(this.windowElement);
        this.mutationObserver.observe(this.windowElement, {childList: true, subtree: true});
        this.checkAndHandleOverflow();
    }

    private checkAndHandleOverflow(): void {
        const oldScrollable = this.scrollable;
        const scrollable = this.contentElement.scrollWidth > this.windowElement.clientWidth;

        if (scrollable !== oldScrollable) {
            this.handleOverflow(scrollable);
        }

        this.scrollable = scrollable;
    }

    private handleOverflow(scrollable: boolean): void {
        this.windowElement.classList.toggle("horizontally-overflown", scrollable);
        if (this.tabbable && scrollable) {
            this.windowElement.setAttribute("tabindex", "0");
        } else {
            this.windowElement.removeAttribute("tabindex");
        }
        this.handlers.forEach(handler => handler.reactToOverflow(scrollable));
    }

    private isResponsibleFor(handler: HorizontalOverflowHandler): boolean {
        return this.windowElement.hasDescendant(handler.element, {transparent: true});
    }

    public disconnect(): void {
        this.handleOverflow(false);
        this.resizeObserver.disconnect();
        this.mutationObserver.disconnect();
    }
}

@autoRegister()
export class HorizontalOverflowService {

    private allHandlers: HorizontalOverflowHandler[];
    private swipeWindows: SwipeWindow[];

    public constructor() {
        this.allHandlers = [];
        this.swipeWindows = [];
    }

    public registerSwipeWindow(windowElement: Element, contentElement: Element): void {
        const swipeWindow = new SwipeWindow(windowElement, contentElement).addRespectiveHandlersFrom(this.allHandlers);
        this.swipeWindows.push(swipeWindow);
        swipeWindow.observeChanges();
    }

    public registerHandler(affectedElement: Element, overflowListener: (overflown: boolean) => void): void {
        const handler: HorizontalOverflowHandler = {element: affectedElement, reactToOverflow: overflowListener};
        this.allHandlers.push(handler);
        this.swipeWindows.forEach(swipeWindow => swipeWindow.tryAddAndExecuteHandler(handler));
    }

    public unregisterSwipeWindow(windowElement: Element): void {
        const swipeWindow = this.swipeWindowForElement(windowElement);
        if (swipeWindow) {
            swipeWindow.disconnect();
            this.swipeWindows.removeAll(swipeWindow);
        }
    }

    public unregisterHandler(affectedElement: Element): void {
        const handler = this.handlerForElement(affectedElement);
        if (handler) {
            this.allHandlers.removeAll(handler);
            this.swipeWindows.forEach(swipeWindow => swipeWindow.removeHandler(handler));
        }
    }

    private swipeWindowForElement(windowElement: Element): SwipeWindow | null {
        return this.swipeWindows.findFirst(swipeWindow => swipeWindow.windowElement === windowElement) ?? null;
    }

    private handlerForElement(element: Element): HorizontalOverflowHandler | null {
        return this.allHandlers.findFirst(handler => handler.element === element) ?? null;
    }
}

export class HorizontalOverflow implements ReadyElementFeature {

    public constructor(
        private swipeWindow: Element,
        private swipeContent: Element = swipeWindow,
        private service: HorizontalOverflowService = resolve(HorizontalOverflowService)
    ) {
    }

    public install(): this {
        this.service.registerSwipeWindow(this.swipeWindow, this.swipeContent);
        return this;
    }

    public uninstall(): void {
        this.service.unregisterSwipeWindow(this.swipeWindow);
    }
}

@autoRegister()
export class HorizontalOverflowInstaller {
    public installOn(swipeWindow: Element, swipeContent: Element = swipeWindow): HorizontalOverflow {
        const horizontalOverflow = new HorizontalOverflow(swipeWindow, swipeContent);
        return horizontalOverflow.install();
    }
}

@autoRegister()
export class HorizontalOverflowFactory implements ElementFeatureFactory<HorizontalOverflow> {
    public installOn(element: Element): HorizontalOverflow {
        const horizontalOverflow = new HorizontalOverflow(element);
        return horizontalOverflow.install();
    }
}