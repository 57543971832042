import {UnLitElement} from "../../../common/elements";
import {property, state} from "lit/decorators.js";
import {COLOR_SCHEME_CHANGED_EVENT, ColorScheme, type ColorSchemeData, ColorSchemeService} from "../../../common/colorSchemeService";
import {resolve} from "../../../container";
import {EventBus, EventBusEvent} from "../../../common/eventBus";
import {ManagingResources} from "../../../common/lifetime";
import {IntersectionObserverFactory, onceIntersected} from "../../../common/observation";
import {OUT_OF_VIEW_ATTR} from "./imageUtils";

export abstract class ColorSchemeAwareImage extends ManagingResources(UnLitElement) {

    @property({attribute: "src"})
    public src: string;
    @property({attribute: "src-dark"})
    public srcDark: string | undefined;
    @property({attribute: "baseplate-enabled", type: Boolean})
    public baseplateEnabled: boolean = false;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;

    @state()
    protected srcCurrent: string;
    @state()
    protected enableBaseplate: boolean = false;

    @state()
    protected intersects: boolean = false;
    private intersectionObserver: IntersectionObserver;

    protected constructor(
        protected colorSchemeService: ColorSchemeService = resolve(ColorSchemeService),
        protected eventBus: EventBus = resolve(EventBus),
        protected intersectionObserverFactory: IntersectionObserverFactory = resolve(IntersectionObserverFactory)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.srcCurrent = this.src ?? "";

        if (this.hasDarkModeImage()) {
            this.toggleSrc(this.colorSchemeService.getColorScheme());
            this.eventBus.on(COLOR_SCHEME_CHANGED_EVENT, (event: EventBusEvent<ColorSchemeData>) => this.toggleSrc(event.data.colorScheme));
        }

        if (this.baseplateEnabled) {
            this.eventBus.on(COLOR_SCHEME_CHANGED_EVENT, (event: EventBusEvent<ColorSchemeData>) => this.toggleBaseplate(event.data.colorScheme));
        }

        this.toggleBaseplate(this.colorSchemeService.getColorScheme());

        this.intersectionObserver = this.intersectionObserverFactory.create(onceIntersected(() => this.intersect()), {threshold: 0.2});
        this.intersectionObserver.observe(this);
    }

    public disconnectedCallback(): void {
        this.intersectionObserver.disconnect();
        super.disconnectedCallback();
    }

    private toggleSrc(colorScheme: ColorScheme): void {
        this.srcCurrent = colorScheme === ColorScheme.LIGHT
            ? (this.src ?? "")
            : (this.srcDark ?? "");
    }

    private toggleBaseplate(colorScheme: ColorScheme): void {
        this.enableBaseplate = this.baseplateEnabled && colorScheme === ColorScheme.DARK;
    }

    private intersect(): void {
        this.intersects = true;
    }

    protected isSrcReady(): boolean {
        if (this.outOfView) {
            return false;
        }

        if (this.isAnimated()) {
            return this.intersects;
        }

        return true;
    }

    protected hasDarkModeImage(): boolean {
        return !!this.srcDark;
    }

    protected abstract isAnimated(): boolean;
}