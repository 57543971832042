import {OUT_OF_VIEW_ATTR} from "./imageUtils";
import {customElement, property, query} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import {ColorSchemeAwareImage} from "./colorSchemeAwareImage";
import {PlainImageAdjuster} from "./plainImageCommons";
import {eopCustomEvent} from "../../../common/utils/events";

@customElement("eop-plain-image")
export class EopPlainImage extends ColorSchemeAwareImage {

    @property({attribute: "alt"})
    public alt: string;
    @property({attribute: OUT_OF_VIEW_ATTR, type: Boolean})
    public outOfView: boolean = false;
    @property({attribute: "functional-icons"})
    private functionalIcons: string = "";
    @property({attribute: "animated", type: Boolean})
    public animated: boolean;

    @query("img")
    private imageElement: HTMLImageElement;

    private imageAdjuster: PlainImageAdjuster;

    public constructor() {
        super();
        this.imageAdjuster = new PlainImageAdjuster(() => this.imageElement, this);
    }

    public render(): TemplateResult {
        return html`
            <div class="plain-image">
                <div class="plain-image-container">
                    <img src=${this.isSrcReady() ? this.srcCurrent : ""}
                         alt=${this.alt ?? ""}
                         loading="lazy"
                         @load=${{handleEvent: () => this.handleImageLoaded(), once: false}}
                         ?baseplate=${this.enableBaseplate}/>
                    ${this.renderFunctionalIcons()}
                </div>
            </div>
        `;
    }

    private renderFunctionalIcons(): TemplateResult | null {
        if (this.functionalIcons.length === 0) {
            return null;
        }
        const icons = this.functionalIcons
            .split(",")
            .map((icon: string) => html`
                <div class=${icon}></div>`);
        return html`${icons}`;
    }

    private handleImageLoaded(): void {
        this.imageAdjuster.adjust(this.srcCurrent);
        this.dispatchEvent(eopCustomEvent("imageLoaded"));
    }

    protected isAnimated(): boolean {
        return this.animated;
    }
}