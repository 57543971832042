import {ScrollService} from "../../common/scroll";
import {ViewportChangeListener} from "../../common/viewportChangeListener";
import {ViewportCallbackBuilder} from "../../common/viewportCallback";
import {resolve} from "../../container";
import {Dictionary} from "../elements/dictionary";
import {ManagingResources} from "../../common/lifetime";
import {Page} from "../../common/page";
import {customElement, property, state} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import Styles from "./backToTop.lit.scss";
import {Resolution} from "../../common/resolution";

@customElement("eop-back-to-top")
export class BackToTop extends ManagingResources(LitElement) {

    public static readonly styles = Styles;

    @property({type: Boolean, reflect: true})
    public invisible: boolean = true;
    @state()
    private threshold: number = 0;
    private readonly THRESHOLD_FACTOR = 3;
    private dictionary: Dictionary;


    public constructor(
        private scrollService: ScrollService = resolve(ScrollService),
        private viewportChangeListener: ViewportChangeListener = resolve(ViewportChangeListener),
        private resolution: Resolution = resolve(Resolution),
        private page: Page = resolve(Page)
    ) {
        super();
        this.dictionary = Dictionary.of(this);
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.threshold = this.calcThreshold();

        this.resolution.onWindowResize(() => {
            this.threshold = this.calcThreshold();
        });

        const viewportCallback = new ViewportCallbackBuilder()
            .withPage(this.page)
            .withPredicate(viewportState => viewportState.yScrollPosition > this.threshold)
            .withCallback(shouldBeVisible => this.invisible = !shouldBeVisible)
            .build();

        this.viewportChangeListener.listenToConditionChange(viewportCallback, this);
    }

    public render(): TemplateResult {
        const backToTopLabel = this.dictionary.translate("MSG_BACK_TO_TOP");
        return html`
            <button class="back-to-top" @click=${this.scrollToTop} aria-label=${backToTopLabel} data-tracking-label="back-to-top"></button>
        `;
    }

    public scrollToTop(): void {
        void this.scrollService.scrollToTop();
    }

    private calcThreshold(): number {
        return this.THRESHOLD_FACTOR * this.page.visualViewportHeight();
    }
}