import {LanguageProfile, LanguagesService} from "../../../../common/languages";
import {resolve} from "../../../../container";
import {customElement} from "lit/decorators.js";
import {html, LitElement, type TemplateResult} from "lit";
import Styles from "./mobileLanguageSwitch.lit.scss";

@customElement("eop-mobile-language-switch")
export class EopMobileLanguageSwitch extends LitElement {

    public static readonly styles = Styles;

    private allLanguages: LanguageProfile[];
    private activeLanguage: LanguageProfile;

    public constructor(private languagesService: LanguagesService = resolve(LanguagesService)) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();
        this.allLanguages = this.languagesService.allLanguages();
        this.activeLanguage = this.languagesService.activeLanguage();
    }

    public render(): TemplateResult | null {
        if (this.allLanguages.isEmpty()) {
            return null;
        }

        return html`
            <div class="language-switch">
                ${this.renderLanguageLink(this.allLanguages.first()!)}
                ${(this.renderLanguageLinks())}
            </div>`;
    }

    public focusableElements(): HTMLElement[] {
        return Array.from(this.renderRoot.querySelectorAll(".link"));
    }

    private renderLanguageLinks(): TemplateResult[] {
        return this.allLanguages.slice(1).map(lang =>
            html` | ${this.renderLanguageLink(lang)}`
        );
    }

    private renderLanguageLink(lang: LanguageProfile): TemplateResult {
        const langDescriptor = lang.id.toUpperCase();
        if (this.activeLanguage.id === lang.id) {
            return html`
                <span class="language active">${langDescriptor}</span>
            `;
        } else {
            return html`
                <a href=${lang.href} class="link language inactive">${langDescriptor}</a>
            `;
        }
    }
}
