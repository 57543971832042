import {customElement, property} from "lit/decorators.js";
import {html, type TemplateResult} from "lit";
import Styles from "./navigationElement.lit.scss";
import {navigationDownEvent} from "./mobileHeaderEvents";
import type {NavigationItem} from "../common/navModel";
import {classMap} from "lit/directives/class-map.js";
import {EopNavigationItemGroup} from "../common/navigationItemGroup";
import {TrackingEventLocation, TrackingEventName, TrackingEventType} from "../../../../tracking/types";

@customElement("eop-navigation-element")
export class EopNavigationElement extends EopNavigationItemGroup {

    public static readonly styles = Styles;

    @property()
    public navItem: NavigationItem;
    @property()
    public level: number;


    public render(): TemplateResult | null {
        if (!this.navItem) {
            return null;
        }

        const active = this.navItem.active || this.navItem.activeForCurrentUrl();
        const classes = classMap({highlighted: active});
        return html`
            <div class="navigation-element ${classes}"
                 data-tracking-event-name=${TrackingEventName.NAVIGATION_INTERACT}
                 data-tracking-payload-label=${this.navItem.label}
                 data-tracking-payload-location=${TrackingEventLocation.HEADER}
                 data-tracking-payload-type=${this.interactionType()}
            >
                ${this.navItem.hasSubItems() ? this.renderNode() : this.renderLeaf()}
            </div>
        `;
    }

    public focusableElement(): HTMLElement | null {
        return this.renderRoot.querySelector("button") || this.renderRoot.querySelector("a");
    }

    private interactionType(): TrackingEventType {
        return this.navItem.hasSubItems() ? TrackingEventType.OPEN : TrackingEventType.CLICK;
    }

    private renderLeaf(): TemplateResult {
        return html`
            <span class="element-level-${this.level}">
                <a href=${this.navItem.href}
                   target=${this.navItem.target}
                   title=${this.navItem.label}
                   class="leaf-element"
                >
                    ${this.navItem.label}
                </a>
            </span>`;
    }

    private renderNode(): TemplateResult {
        return html`
            <button class="node-element element-level-${this.level}" @click=${this.clickLink}>${this.navItem.label}</button>`;
    }

    private clickLink(): void {
        this.dispatchEvent(navigationDownEvent(this.navItem));
    }
}