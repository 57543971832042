import {ModalDialogFactory} from "./modalDialog";
import {Dictionary} from "../dictionary";
import {resolve} from "../../../container";


export class EopDownloadDialog extends HTMLElement {

    public constructor(private modalDialog: ModalDialogFactory = resolve(ModalDialogFactory)) {
        super();
    }

    public connectedCallback(): void {
        const dialogElement = this.querySelector(".download-dialog-content");

        if (!dialogElement) {
            return;
        }

        const dialog = this.modalDialog.create()
            .withDictionary(Dictionary.of(this))
            .withContent(dialogElement);

        const closeButtons = dialogElement.querySelectorAll(".close");
        closeButtons.forEach(element => element.addEventListener("click", () => dialog.fadeOut()));


        if (dialogElement.querySelectorAll(".lightbox-image").length > 0) {
            this.addEventListener("click", event => {
                const isClickOnImage = event.target === this.querySelector("img") || (event.target as HTMLElement).classList.contains("lightbox-image-preview-icon");
                if (isClickOnImage) {
                    event.preventDefault();
                    dialog.open();
                }
            });
        } else if (dialogElement.querySelectorAll(".disclaimer-dialog").length > 0) {
            this.addEventListener("click", event => {
                const isClickOnImageOrLabel = event.target === this.querySelector("img") || (event.target as HTMLElement).classList.contains("download-label");
                if (isClickOnImageOrLabel) {
                    event.preventDefault();
                    dialog.open();
                }
            });
        }
    }

}

customElements.define("eop-download-dialog", EopDownloadDialog);