import {html, type TemplateResult} from "lit";
import {customElement, property, state} from "lit/decorators.js";
import {LoadAfterConfirmationEvents} from "./loadAfterConfirmation";
import {resolve} from "../../container";
import Styles from "./iframeElement.lit.scss";
import {UnLitElement} from "../../common/elements";
import {classMap} from "lit/directives/class-map.js";

@customElement("eop-confirmable-iframe-element")
export class EopConfirmableIframeElement extends UnLitElement {

    public static readonly styles = Styles;

    @property({attribute: "src"})
    private src: string;
    @property({attribute: "module-name"})
    private moduleName: string;
    @property({attribute: "frame-title"})
    private frameTitle: string;
    @property({attribute: "additionalClasses"})
    private additionalClasses: Record<string, boolean> = {};
    @state()
    private dynamicSrc: string;

    public constructor(
        private loadAfterConfirmationEvents: LoadAfterConfirmationEvents = resolve(LoadAfterConfirmationEvents)
    ) {
        super();
    }

    public connectedCallback(): void {
        super.connectedCallback();

        this.loadAfterConfirmationEvents.onConfirmation(this.moduleName, async () => {
            this.dynamicSrc = this.src;
        });
    }

    public render(): TemplateResult {
        return html`
            <div class="embed-responsive embed-responsive-16by9 ${classMap(this.additionalClasses)}">
                <iframe src=${this.dynamicSrc} title=${this.frameTitle}></iframe>
            </div>
        `;
    }
}